import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  public loading: boolean = false;
  private token: string = sessionStorage.getItem('authToken');
  private guid: string = ((new JwtHelperService).decodeToken(this.token)).guid;
  private idUser: number = Number(sessionStorage.getItem('idUser'));
  public fileToUpload: File = null;

  public rgOk: string = '';
  public cpfOk: string = '';
  public cmpResidOk: string = '';
  public cmpRendaOk: string = '';
  public certNascOk: string = '';

  constructor(private userService: UserService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getUploadedFiles();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  getUploadedFiles() {

    try {
      this.userService.getUploadedFiles(this.token, this.guid)
        .subscribe(
          result => {
            this.rgOk = result.rg != null? '- Documento já enviado!' : '';
            this.cpfOk = result.cpf != null? '- Documento já enviado!' : '';
            this.cmpRendaOk = result.comp_renda != null? '- Documento já enviado!' : '';
            this.cmpResidOk = result.comp_residencia != null? '- Documento já enviado!' : '';
            this.certNascOk = result.cert_nascimento != null? '- Documento já enviado!' : '';
          }
        )
    } catch(error) {
      console.log(error);
    }
  }

  uploadFile(fileType: string) {

    if(!this.fileToUpload) {
      return this.snackBar.open('Por favor selecione um arquivo para fazer upload!', 'Erro!', {
        duration: 3000
      })
    }

    try {
      this.loading = true;
      this.userService.uploadFiles(this.token, fileType, this.idUser, this.fileToUpload)
        .subscribe(
          result => {
            this.snackBar.open('Seu arquivo foi enviado com sucesso', 'Tudo certo!', {
              duration: 1500
            });
            this.getUploadedFiles();
            this.loading = false;
          }
        )
    } catch(error) {
      console.log(error);
    }
  }

}
