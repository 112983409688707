import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserActivityService } from 'src/app/Services/user-activity.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  hide: boolean = true;

  messageShow: boolean = false;
  messageTitle: string;
  messageContent: string;
  public userProfile: string;

  guid: string;
  firstName: string;
  lastName: string;
  cpf: string;
  birthDate: string;
  email: string;
  phone: string;

  quizActivity: boolean = false;
  redaction: boolean = false;
  studyCase: boolean = false;
  documents: boolean = false;

  requestionServer:boolean = false;

  constructor(
    private userService: UserService, 
    private route: ActivatedRoute,
    private userActivity: UserActivityService
  ) { }

  ngOnInit(): void {

    const token = sessionStorage.getItem('authToken');
    const guid = this.route.snapshot.paramMap.get('guid');

    this.userProfile = ((new JwtHelperService).decodeToken(token)).profile;

    this.userService.getUserData(token, guid)
      .subscribe(
        result => {
          this.guid = result.data.guid;
          this.firstName = result.data.first_name;
          this.lastName = result.data.last_name;
          this.cpf = result.data.cpf;
          this.birthDate = result.data.birth_date;
          this.email = result.data.email;
          this.phone = result.data.phone_number;
        },
        error => {
          console.log(error);
          this.messageBox('Erro', 'Houve um erro ao carregar os dados');
        }
      )

    this.userActivity.getActivity(token, guid, 'atualidades')
        .subscribe(
          result => {
            this.quizActivity = true;
          },
          error => {
            if(error == 404) {
              this.quizActivity = false;
            }
          }
        )

    this.userActivity.getActivity(token, guid, 'redacao')
    .subscribe(
      result => {
        this.redaction = true;
      },
      error => {
        if(error == 404) {
          this.redaction = false;
        }
      }
    )

    this.userActivity.getActivity(token, guid, 'estudo_caso')
    .subscribe(
      result => {
        this.studyCase = true;
      },
      error => {
        if(error == 404) {
          this.studyCase = false;
        }
      }
    )

    this.userService.getUploadedFiles(token, guid)
      .subscribe(
        result => {
          if(
            result.cert_nascimento  != null ||
            result.comp_renda       != null ||
            result.comp_residencia  != null ||
            result.cpf              != null ||
            result.rg               != null
          ) {
            this.documents = true;
          }
        },
        error => {
          if(error == 404) {
            this.documents = false;
          }
        }
      )
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
  this.messageTitle = title;
  this.messageContent = content;
  this.toogleMessageShow(true);
  window.scrollTo(0, 0);
  }

}
