import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-goodbye',
  templateUrl: './goodbye.component.html',
  styleUrls: ['./goodbye.component.css']
})
export class GoodbyeComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService
    ) { }

  messageShow: boolean = false;
  messageTitle: string;
  messageContent: string; 

  ngOnInit(): void {

    this.userService.getSubscriptionStatus(sessionStorage.getItem('authToken'))
    .subscribe(
      result => {
        if(result.is_register_valid == true) {
          return this.router.navigate(['plataforma-candidato']);
        }
      },
      error => {
        this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
        console.log(error);
      }
    )

      sessionStorage.setItem('authToken','');
      window.scrollTo(0, 0);
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

}
