import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt'
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-register-confirmation',
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['./register-confirmation.component.css']
})
export class RegisterConfirmationComponent implements OnInit {

  messageShow: boolean = false;
  email: string;
  password: string;
  messageTitle: string;
  messageContent: string;

  private warnings: string[] = [
    " Início da nova turma",
    " Polo mais próximo",
    " Conteudo Institucional"
   ];

   public disabledButtonConfirmar: boolean = true;

   public userName:  string = "";
   public userCPF:   string = "";
   public userPhone: string = "";
   public userIdade: number;
   public userFirstName: string = "";
   public userLastName: string = "";
   public userBirthDay: Date;


  constructor( private router: Router, private userService: UserService ) { }

  ngOnInit(): void {

    const jwt = new JwtHelperService();
    const jwtToken = sessionStorage.getItem('authToken');
    const guid = (jwt.decodeToken(jwtToken)).guid;

    this.userService.getSubscriptionStatus(sessionStorage.getItem('authToken'))
    .subscribe(
      result => {
        if(result.is_register_valid == true) {
          return this.router.navigate(['plataforma-candidato']);
        }
      },
      error => {
        this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
        console.log(error);
      }
    )

    if ( !sessionStorage.getItem('authToken') ) {
      this.router.navigate(['/login']);
    }

    this.userService.getUserData(jwtToken, guid)
      .subscribe(result => {
        this.userCPF = result.data.cpf;
        this.formatCPF(this.userCPF);
        this.userPhone = (result.data.phone_number ? result.data.phone_number : '');
        this.formatPhone(this.userPhone);
        this.userFirstName = (result.data.first_name ? result.data.first_name : '');
        this.userLastName = (result.data.last_name ? result.data.last_name : '');
        this.userBirthDay = result.data.birth_date;
      }, failure => {
        if(failure.error.code == 2) {
          this.messageBox("Ops!",failure.error.message);
          this.router.navigate(['/login']);
          //TODO CONTONAR SITUAÇÃO DO POPUP
        } else {
          console.log(failure);
          this.messageBox('Erro', 'Houver um erro ao processar sua requisição: ' + failure.error);
        }

      })
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  formatCPF(cpf){
    let newCPF = cpf;
    newCPF = cpf.replace(/[^0-9]/g,"");
    if ( newCPF.length < 4 )
      this.userCPF = newCPF;
    else if ( newCPF.length < 7 )
      this.userCPF = newCPF.substring(0,3) + '.' + newCPF.substring(3,6);
    else if ( newCPF.length < 10 )
      this.userCPF = newCPF.substring(0,3) + '.' + newCPF.substring(3,6) + '.' + newCPF.substring(6,9);
    else if ( newCPF.length < 12 )
      this.userCPF = newCPF.substring(0,3) + '.' + newCPF.substring(3,6) + '.' + newCPF.substring(6,9) + '-' + newCPF.substring(9,11);
    else
      this.userCPF = newCPF.substring(0,3) + '.' + newCPF.substring(3,6) + '.' + newCPF.substring(6,9) + '-' + newCPF.substring(9,11);
  }

  formatPhone(phone){
    let newPhone = phone;
    newPhone = phone.replace(/[^0-9]/g,"");
    if( newPhone.length < 2 )
      this.userPhone = "(" + newPhone;
    else if ( newPhone.length < 3 )
      this.userPhone = "(" + newPhone + ")";
    else if ( newPhone.substring(2,3) === "9") {
      if ( newPhone.length < 8 )
        this.userPhone =  "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,7);
      else
        this.userPhone =  "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,7) + "-" + newPhone.substring(7,11);
    }
    else{
      if ( newPhone.length < 7 )
        this.userPhone =  "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,6);
      else
        this.userPhone =  "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,6) + "-" + newPhone.substring(6,10);
    }
  }

  isCpfValid(cpf): boolean {
    let result = false;
    let newCPF = cpf;
    let digitoVerificador1: number = 0;
    let digitoVerificador2: number = 0;

    newCPF = cpf.replace(/[^0-9]/g,"");
    let numbersOfCpf = newCPF.split('');

    digitoVerificador1 = (
      (
        (
          ( numbersOfCpf[0]  * 10 ) +
          ( numbersOfCpf[1]  *  9 ) +
          ( numbersOfCpf[2]  *  8 ) +
          ( numbersOfCpf[3]  *  7 ) +
          ( numbersOfCpf[4]  *  6 ) +
          ( numbersOfCpf[5]  *  5 ) +
          ( numbersOfCpf[6]  *  4 ) +
          ( numbersOfCpf[7]  *  3 ) +
          ( numbersOfCpf[8]  *  2 )
        ) * 10
      ) % 11
    )

    digitoVerificador2 = (
      (
        (
          ( numbersOfCpf[0]  * 11 ) +
          ( numbersOfCpf[1]  * 10 ) +
          ( numbersOfCpf[2]  *  9 ) +
          ( numbersOfCpf[3]  *  8 ) +
          ( numbersOfCpf[4]  *  7 ) +
          ( numbersOfCpf[5]  *  6 ) +
          ( numbersOfCpf[6]  *  5 ) +
          ( numbersOfCpf[7]  *  4 ) +
          ( numbersOfCpf[8]  *  3 ) +
          ( numbersOfCpf[9]  *  2 )
        ) * 10
      ) % 11
    )

    if ( digitoVerificador1 === 10 ) digitoVerificador1 = 0;
    if ( digitoVerificador2 === 10 ) digitoVerificador2 = 0;

    if (
      numbersOfCpf[0] === numbersOfCpf[1] &&
      numbersOfCpf[1] === numbersOfCpf[2] &&
      numbersOfCpf[2] === numbersOfCpf[3] &&
      numbersOfCpf[3] === numbersOfCpf[4] &&
      numbersOfCpf[4] === numbersOfCpf[5] &&
      numbersOfCpf[5] === numbersOfCpf[6] &&
      numbersOfCpf[6] === numbersOfCpf[7] &&
      numbersOfCpf[7] === numbersOfCpf[8] &&
      numbersOfCpf[8] === numbersOfCpf[9] ) {

        this.messageBox("Ops!","Este CPF não é válido!");

        result = false;
    }
    else if ( digitoVerificador1 == numbersOfCpf[9] && digitoVerificador2 == numbersOfCpf[10] ) {
        result = true;
    }
    else
    {
      this.messageBox("Ops!","Este CPF não é válido!");
    }
    return result;
  }

  getAge(): number {
    let nasc = new Date(this.userBirthDay);
    const hoje = new Date();
    var diferencaAnos = hoje.getFullYear() - nasc.getFullYear();
    if ( new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) < new Date(hoje.getFullYear(), nasc.getMonth(), nasc.getDate()) ) {
      diferencaAnos--;
    }
    return diferencaAnos;
  }

  checkInputs(): boolean{
    if ( this.userFirstName === "" )
      return false;
    if ( this.userLastName === "" )
      return false;
    else if ( this.userCPF === "")
      return false;
    else if ( this.userPhone === "")
      return false;
    else if ( this.getAge() > 24 || this.getAge() < 17 )
      return false;
    else{
      this.disabledButtonConfirmar = false;
      return true;
    }
  }

  sendForm() {
    if ( this.checkInputs() ) {
      let tempCPF: string = this.userCPF.replace(/[^0-9]/g,"")
      let tempPhone: string = this.userPhone.replace(/[^\d]/g, '');

      let requestBody = {
        first_name: this.userFirstName,
        last_name: this.userLastName,
        phone_number: tempPhone,
        birth_date: this.userBirthDay,
        cpf: tempCPF,
      };

      this.userService.updateUser(requestBody, sessionStorage.getItem("authToken"))
      .subscribe(result => {
        if(result.code == 1) {
          this.messageBox("Sucesso!", result.message);
          this.router.navigate(['/inscricao-completa']);
          //changeStatusRegistration(true);
        }
      }, failure => {
        console.log(failure)
        this.messageBox("Ops!", "Não foi possível acessar ao servidor")
      });
    }
    else {
      this.messageBox("Ops!","Verifique se um dos campos não foi preenchido ou se você não atente a um dos requisitos da seleção");
    }
  }
}
export interface SectionWarnings {
  icon: String;
  text: String;
}

export interface SectionDocuments {
  icon: String;
  text: String;
}
@Component({
  selector: 'app-list-section-warning',
  styleUrls: [ ],
  templateUrl: './register-listwarning.component.html',
})
export class ListSectionsWarning {
  warnings: SectionWarnings[] = [
    {
      icon: "calendar_today",
      text: " Início da proxima turma",
    },
    {
      icon: "location_on",
      text: " Polo mais próximo",
    },
    {
      icon: "home",
      text: " Conteúdo institucional",
    }
  ];
  documents: SectionDocuments[] = [
    {
      icon: "check",
      text: "RG/CPF/CNH",
    },
    {
      icon: "check",
      text: "Certidão Nascimento",
    },
    {
      icon: "check",
      text: "Declaração Escolar",
    },
    {
      icon: "check",
      text: "Comprovante Renda",
    }
  ]
}





