<div class="form-content">
    <div class="container">
        <br>
        <mat-card class="redactionCard">
            <mat-card-title>Notas das questões de múltipla escolha - {{ currentUser }}</mat-card-title>
            <br>
            <br>
            <mat-card-content>
                <div>
                    <table>
                        <tr>
                            <td>
                                <h4>
                                    <strong>Mercado de trabalho</strong>
                                </h4>
                            </td>
                            <td>
                                <mat-spinner diameter="25" *ngIf="!mercadoTrabalhoAns"></mat-spinner>
                                <strong>
                                    {{ mercadoTrabalhoAns }}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>
                                    <strong>
                                        Atualidades
                                    </strong>
                                </h4>
                            </td>
                            <td>
                                <mat-spinner diameter="25" *ngIf="!atualidadesAns"></mat-spinner>
                                <strong> {{ atualidadesAns }} </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>
                                    <strong>
                                        Raciocínio Lógico
                                    </strong>
                                </h4>
                            </td>
                            <td>
                                <mat-spinner diameter="25" *ngIf="!raciocinioAns"></mat-spinner>
                                <strong> {{ raciocinioAns }} </strong>
                            </td>
                        </tr>
                    </table>
                </div>
                <div fxLayout="row" fxLayoutAlign="end" class="textAlignEnd button-back">
                    <button mat-button color="secondary" [routerLink]="['/plataforma-tutor']">Voltar</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>