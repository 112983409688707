<div class="form-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button *ngIf="!accountUpdated" mat-raised-button color="warn" (click)="toogleMessageShow(false);">OK</button>
                <button *ngIf="accountUpdated" mat-raised-button color="warn" (click)='Redirect()'>OK</button>
            </div>
        </mat-card>
    </div>
    <br>
    <div class="bg-darken" *ngIf="modalShow">
        <mat-card id="modal-LGPD">
            <mat-card-title> {{ modalTitle }} </mat-card-title>
            <mat-card-content>
                <div class='container' style='width: 100%; max-height: 400px'>
                    <div class='responsive-pdf-screen'>
                        <pdf-viewer
                            [src]="'https://tamboro.blob.core.windows.net/tamboro-nau-prod/terms/2020_11_13_TermosUsoTamboroLGPD-NAU.pdf'"
                            [render-text]="true"
                            [zoom]='2.1'
                            [original-size]="false"
                            style="width: 100%; height: 400px"
                        ></pdf-viewer>
                    </div>
                    <iframe class='normal-pdf-screen'
                        src="https://tamboro.blob.core.windows.net/tamboro-nau-prod/terms/2020_11_13_TermosUsoTamboroLGPD-NAU.pdf" type="application/pdf"
                        title="SamplePdf" width="500" height="400">
                        <a href="https://tamboro.blob.core.windows.net/tamboro-nau-prod/terms/2020_11_13_TermosUsoTamboroLGPD-NAU.pdf"></a> 
                    </iframe>
                </div>
            </mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd">
                <button mat-stroked-button (click)="declineLGDP()">Recusar</button>
                <button mat-raised-button color="primary" (click)="acceptLGDP()">Aceitar</button>
            </div>
        </mat-card>
    </div>
    <mat-card>
        <mat-card-title>Segunda Etapa</mat-card-title>
        <mat-card-subtitle>
            Confirme seus dados pessoais e complete seu perfil
        </mat-card-subtitle>
        <mat-card-content>
            <form [formGroup]='form'>
                <mat-form-field appearance="fill" layout-align="center center">
                    <mat-label>Nome</mat-label>
                    <input matInput
                        [(ngModel)]="user.firstName"
                        type="text" name="name"
                        formControlName="firstName"/>
                    <mat-error
                        *ngIf="getFormErrors('firstName')">
                        Digite o seu nome!
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field appearance="fill" layout-align="center center">
                    <mat-label>Sobrenome</mat-label>
                    <input matInput
                        [(ngModel)]="user.lastName"
                        type="text" name="name"
                        formControlName="lastName"/>
                    <mat-error
                        *ngIf="getFormErrors('lastName')">
                        Digite o seu sobrenome!
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" layout-align="center center">
                    <mat-label>WhatsApp com DDD</mat-label>
                    <input
                        matInput
                        type="text"
                        [(ngModel)]="userData.phone"
                        (keyup)="formatPhone(form.value.phone)"
                        formControlName="phone"/>
                    <mat-error
                        *ngIf="form.controls.phone.hasError('minlength')">
                        O número de WhatsApp o (DDD) + 8 a 9 digitos
                    </mat-error>
                    <mat-error
                        *ngIf="form.value.phone == '' ">
                        Você precisa digitar um número de WhatsApp
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field appearance="fill">
                    <mat-label>Data de Nascimento</mat-label>
                    <input matInput
                        [matDatepicker]="picker" formControlName="birthDay">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                        *ngIf="form.value.birthDay == '' || form.value.birthDay == null"
                    >
                    Você deve colocar a sua data de aniversário
                    </mat-error>
                    <mat-error
                        *ngIf="form.get('birthDay').hasError('invalidAge') 
                        && form.value.birthDay != '' "
                    >
                        É necessário ter entre 16 a 24 anos.
                    </mat-error>
                </mat-form-field>
            </form>
            <mat-error *ngIf="error" style='margin: 2rem 0;'>
                {{errorMessage}}
            </mat-error>

        </mat-card-content>
    </mat-card>
    <br>
    <br>
    <mat-card>
        <mat-card-title>Inscrição - Critérios seletivos</mat-card-title>
        <mat-card-content>
            <mat-selection-list #listOptions>
                <mat-list-option
                    [id]='i' *ngFor="let criterio of criterios; let i = index"
                    value="cristerios.isSelect"
                    [selected]="criterio.isSelect"
                    (click)="criterioClick(i)"
                    [attr.data-index]="i" color="primary">
                    {{ criterio.text }}
                </mat-list-option>
            </mat-selection-list>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="end" class="textAlignEnd">
                <button 
                    class="textAlignStart"
                    mat-raised-button color="primary" (click)="onFormData()"
                >
                    Cancelar
                </button>
                <button mat-raised-button color="primary" 
                    [disabled]="this.form.status == 'INVALID' && !(this.checkCriterios() && this.checkInputs())"
                    (click)="sendForm()">
                    Enviar
                </button>
                <mat-progress-bar
                    *ngIf="requestionServer"
                    mode="indeterminate">
                </mat-progress-bar>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-title>Downloads</mat-card-title>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignInitial">
                <a mat-raised-button color="primary" href="assets/pdf/edital-nau.pdf" target="_blank">Edital</a>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <br>
    <br>
</div>
