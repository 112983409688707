
<div class="bg-darken" *ngIf="messageShow">
    <mat-card>
        <mat-card-title> {{ messageTitle }} </mat-card-title>
        <mat-card-content> {{ messageContent }} </mat-card-content>
        <br>
        <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
            <button mat-raised-button color="warn" [routerLink]="['/plataforma-tutor']">OK</button>
        </div>
    </mat-card>
</div>
<!-- <div class="bg-darken" *ngIf="displayDoc" (click)="closeDocument()">
    <button  class="close-button" mat-icon-button color="primary" (click)="closeDocument()">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div> -->
<div class="documents-content" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
        <mat-card-title>Aprovar documentos - {{ currentUser }}</mat-card-title>
        <mat-card-content>
            <table class="mat-elevation-z0">
                <thead>
                    <tr>
                        <th>
                            Documento
                        </th>
                        <th>
                            Situação
                        </th>
                    </tr>
                </thead>
                <tr>
                    <td>
                        <a mat-stroked-button color="primary" [href]="this.rg.url">RG</a>
                    </td>
                    <td>
                        <button mat-flat-button [class]="this.rg.class" (click)="toggleDocSituation('rg')">{{ this.rg.situation }}</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a mat-stroked-button color="primary" [href]="this.cpf.url">CPF</a>
                    </td>
                    <td>
                        <button mat-flat-button [class]="this.cpf.class" (click)="toggleDocSituation('cpf')">{{ this.cpf.situation }}</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a mat-stroked-button color="primary" [href]="this.certNasc.url">Certidão de nascimento</a>
                    </td>
                    <td>
                        <button mat-flat-button [class]="this.certNasc.class" (click)="toggleDocSituation('cert_nasc')">{{ this.certNasc.situation }}</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a mat-stroked-button color="primary" [href]="this.cmpRenda.url">Comprovante de renda</a>
                    </td>
                    <td>
                        <button mat-flat-button [class]="this.cmpRenda.class" (click)="toggleDocSituation('cmp_renda')">{{ this.cmpRenda.situation }}</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a mat-stroked-button color="primary" [href]="this.cmpResid.url">Comprovante de residência</a>
                    </td>
                    <td>
                        <button mat-flat-button [class]="this.cmpResid.class"  (click)="toggleDocSituation('cmp_resid')">{{ this.cmpResid.situation }}</button>
                    </td>
                </tr>
            </table>
            <span  *ngIf="saveMessage" class="success"><strong>Suas alterações foram salvas!</strong></span><br>
            <div class="button-right">
                <button mat-button [routerLink]="['/plataforma-tutor']">Voltar</button>
                <button mat-raised-button color="primary" (click)="save(true)">Salvar</button>
            </div>
        </mat-card-content>
    </mat-card>
    <br>

</div>