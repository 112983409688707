import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Services/user.service';
import { DialogService } from '../form/dialog.service';

@Component({
  selector: 'app-stay-with-us',
  templateUrl: './stay-with-us.component.html',
  styleUrls: ['./stay-with-us.component.css']
})
export class StayWithUsComponent implements OnInit {

  messageShow: boolean = false;
  messageTitle: string;
  messageContent: string;

  accountUpdated: boolean = false;

  /*  */

  requestionServer: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    public dialogService: DialogService 
    ) { }

  ngOnInit(): void {

    this.userService.getSubscriptionStatus(sessionStorage.getItem('authToken'))
    .subscribe(
      result => {
        if(result.is_register_valid == true) {
          return this.router.navigate(['plataforma-candidato']);
        }
      },
      error => {
        this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
        console.log(error);
      }
    )

    window.scrollTo(0, 0);
    if ( !sessionStorage.getItem('authToken') ) {
      this.router.navigate(['/login']);
    }
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  userAuthExit() {
    this.router.navigate(['/ate-mais']);
  }

  Redirect() {
    this.router.navigate(['/inscricao-completa']);
  }

  sendForm() {
    let dialog = this.dialogService.dialog;
    if (Object.keys(dialog.value).length !== 0) {
      this.requestionServer = true;
      this.userService.updateUser(dialog.value, sessionStorage.getItem("authToken"))
      .subscribe(result => {
        if(result.code == 1) {
          this.dialogService.resetDialog();
          this.requestionServer = false;
          this.messageBox("Sucesso!", result.message);
          this.accountUpdated = true
        }
      }, failure => {
        this.requestionServer = false;
        this.messageBox("Ops!", "Na comunicação com o servidor   : " + JSON.stringify(failure.statusText));
      });
    }
    else {
      this.router.navigate(['/inscricao']);
    }
  }
}
