import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserActivityService } from 'src/app/Services/user-activity.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {

  public mercadoTrabalhoAns: string;
  public raciocinioAns: string;
  public atualidadesAns: string;

  public currentUser = sessionStorage.getItem('currentUser');

  constructor(private activityService: UserActivityService, private route: ActivatedRoute, private router: Router) { }


  ngOnInit(): void {

    const jwtToken = sessionStorage.getItem('authToken');
    const candidateGuid = this.route.snapshot.paramMap.get("guid");
    
    try {
      this.activityService.getActivity(jwtToken, candidateGuid, 'mercado_de_trabalho')
      .subscribe(
        data => {
          let allAnsw = data.result[0].playerAnswers.length;
          let correctAnsw = data.result[0].numCorrectAns;
          this.mercadoTrabalhoAns = correctAnsw + "/" + allAnsw;
          this.activityService.getActivity(jwtToken, candidateGuid, 'raciocinio_logico')
            .subscribe(
              data => {
                let allAnsw = data.result[0].playerAnswers.length;
                let correctAnsw = data.result[0].numCorrectAns;
                this.raciocinioAns = correctAnsw + "/" + allAnsw;
                this.activityService.getActivity(jwtToken, candidateGuid, 'atualidades')
                  .subscribe(
                    data => {
                      let allAnsw = data.result[0].playerAnswers.length;
                      let correctAnsw = data.result[0].numCorrectAns;
                      this.atualidadesAns = correctAnsw + "/" + allAnsw;
                    }
                  )

              }
            )
          
        },
        error => {

          if(error.status == 401) {
            alert('Sua sessão expirou, efetue login novamente!');
            return this.router.navigate(['login']);
          }
          // TODO - substituir por dialog
          alert('Este usuário ainda não realizou a etapa de perguntas múltipla escolha');
          console.log(error);
        },
      )
    } catch(error) {
      // TODO - substituir por dialog
      alert('Este usuário ainda não realizou a etapa de perguntas múltipla escolha');
      console.log(error);
    }
    
    
  }

}
