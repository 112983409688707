import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  criterios: string[] = [
    " A - Cebolinha",
    " B - Batman",
    " C - Hulk",
    " D - Se eu descobrir eu mato ele",
   ]

  constructor() { }

  ngOnInit(): void {
  }

}
