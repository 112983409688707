import { Component, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserActivityService } from 'src/app/Services/user-activity.service';

@Component({
  selector: 'app-redaction',
  templateUrl: './redaction.component.html',
  styleUrls: ['./redaction.component.css']
})
export class RedactionComponent implements OnInit {

  public workFocus: number = 0;
  public organization: number = 0;
  public persistence: number = 0;
  public problemSolving: number = 0;
  public redactionContent: string;
  public saveMessage: boolean = false;

  public loading:boolean = false;

  requestionServer: boolean = false;

  messageTitle: string;
  messageContent: string;
  messageShow: boolean = false;
  public currentUser = sessionStorage.getItem('currentUser');
  public userId = Number(sessionStorage.getItem('idUser'));
  private token = sessionStorage.getItem('authToken');

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  constructor(private activityService: UserActivityService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    const candidateGuid = this.route.snapshot.paramMap.get("guid");
    const jwtHelper = new JwtHelperService();

    this.loading = true;
    this.activityService.getActivity(this.token, candidateGuid, 'redacao')
      .subscribe(
        data => {
          this.loading = false;
          this.redactionContent = data.result[0].stages[0].questions[0].answer;
          //const idUser = Number(this.route.snapshot.paramMap.get("id_user"));
          this.activityService.getCorrection(this.token, this.userId)
            .subscribe(
              result => {
                this.workFocus = result.job_focus_score || 0;
                this.persistence = result.persistence_score || 0;
                this.problemSolving = result.problem_resolution_score || 0;
                this.organization = result.historical_organization || 0;
              },
              error => {
                if(error == 404) {
                  return;
                }
                console.log(error);
              }
            )
        },
        error => {
          if(error.status == 401) {
            this.messageBox('Erro', 'Sua Sua sessão expirou, efetue login novamente!');
          }
          if(error.status == 404) {
              return;
          }
          console.log(error);
        }
      )
  }

  saveCorrection(autoSave: boolean | Optional) {
    try {
      this.requestionServer = true;
      this.activityService.saveCorrection(this.token, this.userId, {
        job_focus_score: this.workFocus,
        historical_organization: this.organization,
        persistence_score: this.persistence,
        problem_resolution_score: this.problemSolving
      })
        .subscribe(
          result => {
            if(autoSave) {
              this.saveMessage = true;
              setTimeout(() => {
                this.saveMessage = false;
              }, 2000);
              return;
            }
            alert('Suas alterações foram salvas!');
          }
        )
    } catch(error) {
      this.requestionServer = false
      console.log(error);
      this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
    }

  }

}
