import { Component, OnInit } from '@angular/core';
import { 
  FormGroup, FormBuilder, Validators,
  AbstractControl, ValidatorFn
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RecoveryPassword } from 'src/app/Model/e-mail.class';
import { PasswordRecoveryService } from 'src/app/Services/password-recovery.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {

  /* form */
  form: FormGroup;
  hide: boolean = true;
  hideCheckedPwd = true;

  token: string | null;
  requestionServer: boolean = false;
  recovered: boolean = false;

  /* model */
  recoveryPassword: RecoveryPassword = new RecoveryPassword();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder : FormBuilder,
    private passwordRecoveryService: PasswordRecoveryService) {
      this.form = this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(6)]],
        password_confirm: [
          '', [Validators.required, Validators.minLength(6), this.PasswordsValidator()]
        ]
      })
    } 
  
  /* verification, messages */
  messageShow: boolean = false;

  errorMessage: boolean = false;

  messageTitle: string;
  messageContent: string;
  idUser:number;

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('tkn');
    this.passwordRecoveryService.validatePassToken(this.token)
      .subscribe(
        result => {
          this.idUser = result.idUser;
        },
        error => {
          this.messageBox('Erro', 'O token para recuperação de senha é inválido!');
        }
      )
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }


  verifyFields(): string {
    let msg = ""
    if (this.form.value.password == undefined) {
      this.errorMessage = true;
      return msg = "Digite uma Senha"
    }
    else if (this.form.value.password == "") {
      this.errorMessage = true;
      return msg = "Digite uma Senha"
    }
    else if (String(this.form.value.password).length < 6) {
      this.errorMessage = true;
      return msg = "A senha precisa ter mais que 6 caracteres"
    }
  }

  PasswordsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden;
      if (control.value) {
        if (this.form.value.password !== control.value)  {
          forbidden = true;
        }
        else {
          forbidden = false;
        }
      }
      return forbidden ? { 'invalidPasswords': true } : null;
    };
  }

  newPassword() {
    const requestBody = {
      pass: this.form.value.password,
      tkn: this.token
    }
    this.requestionServer = true;
    this.passwordRecoveryService.newPassword(requestBody)
      .subscribe(
        result => {
          this.requestionServer = false;
          this.messageBox('Senha alterada com sucesso!', 'Agora anota aí para não esquecer, ok? :)');
          this.recovered = true;
        },
        error => {
          this.requestionServer = false;
          this.messageBox('Ops!', 'Houve um erro inesperado na requisição!');
        }
      )
  }
}
