import { Component, OnInit, ViewChild } from '@angular/core';
import { 
  FormBuilder, FormGroup,
  Validators, ValidatorFn, AbstractControl, FormControlName, FormControl

} from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../../Model/user.class';
import { UserService } from '../../Services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  user: User = new User();

  hide: boolean = true;
  hideCheckedPwd = true;
  hidecheckedpwd: boolean = true;

  messageShow: boolean = false;
  messageTitle: string;
  messageContent: string; 
  resultMessage: string = ``

  modalShow: boolean = false;
  modalTitle: string;
  modalContent: string;

  error: boolean = false;
  errorMessage: string = "";

  accountCreated: boolean = false;

  resultAuth : any;
  requestionServer:boolean = false;

  cadastroForm: FormGroup;

  constructor( 
      private router: Router,  
      private userService: UserService,
      private fb: FormBuilder  
  ) { }

  ngOnInit(): void {
    this.cadastroForm = this.fb.group ({
      fcFirstName : ['', Validators.required],
      fcLastName : ['', Validators.required],
      fcEmail : ['', [Validators.required, Validators.email, this.EmailsValidator]],
      fcCheckEmail : ['', [Validators.required, this.EmailsValidator()]],
      fcWhatsApp : ['', [ Validators.required, Validators.minLength(13) ] ],
      fcPassword: ['', [ Validators.required, Validators.minLength(6) ] ],
      fcRePassword: ['', [ 
        Validators.required, Validators.minLength(6), 
        this.PasswordsValidator()]]
    });
    localStorage.removeItem('subscriptionValid')

    if(!localStorage.getItem('LGPD')) {
      this.modal('Lei geral de proteção de dados', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum erat ut mi viverra fermentum. Maecenas malesuada tempus urna. Proin tincidunt, nibh vel feugiat ultrices, leo lectus scelerisque orci, a ultricies lorem odio id purus. Sed tincidunt egestas dolor, id laoreet elit ultrices quis. Pellentesque non neque a lacus sollicitudin venenatis. Maecenas semper dictum lacinia. Morbi malesuada dolor ut elit rhoncus vulputate. Proin pretium pellentesque lorem eu commodo. In laoreet interdum purus, id sagittis ipsum volutpat ac. Proin volutpat eget ipsum vitae pulvinar. Cras ac dolor neque. Etiam tincidunt neque varius, scelerisque ligula sit amet, blandit dui. Phasellus vitae quam dui. Etiam nec dapibus nibh. Phasellus dui nulla, blandit sed nisl sed, sollicitudin venenatis leo. Mauris vel consequat ligula.');
    }
   }

   formatPhone(whatsApp){
    let newPhone = whatsApp;
    newPhone = whatsApp.replace(/[^0-9]/g,"");
    if( newPhone.length < 2 )
      this.user.whatsApp = "(" + newPhone;
    else if ( newPhone.length < 3 )
    this.user.whatsApp = "(" + newPhone + ")";
    else if ( newPhone.substring(2,3) === "9") {
      if ( newPhone.length < 8 )
      this.user.whatsApp =  "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,7);
      else
      this.user.whatsApp =  "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,7) + "-" + newPhone.substring(7,11);
    }
    else {
      if ( newPhone.length < 7 )
      this.user.whatsApp =  "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,6);
      else
      this.user.whatsApp =  "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,6) + "-" + newPhone.substring(6,10);
    }
  }

  acceptLGDP(): void {
    this.modalShow = false;
    localStorage.setItem('LGPD', 'true');
  }

  declineLGDP(): void{
    this.modalShow = false;
    this.router.navigate(['/login'])
  }

  toggleModalShow(show: boolean): void {
    this.modalShow = show;
  }

  modal(title: string, content: string): void {
    this.modalTitle = title;
    this.modalContent = content;
    this.toggleModalShow(true);
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    if(title == 'Conta criada com sucesso!') {
      this.resultMessage = `
      Fique de olho! Esse e-mail pode chegar no seu <b>spam</b>.
      <br><br>
      Procure direitinho para confirmar sua conta!`
    }
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  EmailsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden;
      if (control.value) {
        if (this.cadastroForm.value.fcEmail !== control.value) {
          forbidden = true;
        }
        else {
          forbidden = false;
        }
      }
      return forbidden ? { 'invalidEmails': true } : null;
    };
  }

  PasswordsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden;
      if (control.value) {
        if (this.cadastroForm.value.fcPassword !== control.value) {
          forbidden = true;
        }
        else {
          forbidden = false;
        }
      }
      return forbidden ? { 'invalidPasswords': true } : null;
    };
  }

  getFormErrors(input: string): boolean {
    if (this.cadastroForm.value.input == undefined){
      return true
    }
    else if (this.cadastroForm.value.input === "") {
      return true;
    }
  }

  userRegister() {
    this.user = Object.assign({}, this.user, this.cadastroForm.value);
    if ( this.cadastroForm.valid ) {
      this.requestionServer = true;
      
      this.userService.createUser(this.user)
      .subscribe( result => {
          console.log(result);
          if(result.code == 1) {
            this.messageBox( 'Conta criada com sucesso!', result.message );
            this.accountCreated = true;
            this.requestionServer = false;
          }
        },
        failure => {
          if(failure.error.code == 2) {
            this.messageBox('Ops!', failure.error.message);
          } else {
            this.messageBox('Ops!', 'Não foi possivel se comunicar com o servidor');
          }
          this.requestionServer = false;
        });
      }
    }
  }