<div class="password-recovery-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button mat-raised-button color="warn" (click)='toogleMessageShow(false);'>
                    OK
                </button>
            </div>
        </mat-card>
    </div>
    <br>
    <mat-card>
        <mat-card-title>Recuperar senha</mat-card-title>
        <mat-card-content>
            <form [formGroup]='form'>
                <mat-form-field appearance="fill">
                    <mat-label
                    [ngClass]="{ 'form.valid': 'colorlightgreen' }">
                        Digite seu e-mail : </mat-label>
                    <input matInput
                        type="email"
                        formControlName = "email"
                        [(ngModel)]="recoveryEmail.email"
                        (keyup)="formatEmail($event.target.value)" />
                    <mat-icon matSuffix *ngIf="form.valid"
                    [ngClass]="{'form.valid': 'colorlightgreen' }">
                        checked
                    </mat-icon>
                    <mat-hint *ngIf='email == undefined'>contato@nau.com.br</mat-hint>
                    <mat-error *ngIf="form.status == 'INVALID' || form.value.email == undefined ">
                        {{ getEmailErrorMessage() }}
                    </mat-error>
                </mat-form-field>
            </form>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd">
                <button mat-raised-button [routerLink]="['/login']">Voltar</button>
                <button
                    [disabled]="!(form.valid)"
                    mat-raised-button color="primary"
                    (click)='pwdRecovery()'>
                        Recuperar Senha
                </button>
                <mat-progress-bar *ngIf="requestionServer" mode="indeterminate"></mat-progress-bar>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
</div>