<div class="bg-darken" *ngIf="messageShow">
    <mat-card>
        <mat-card-title> {{ messageTitle }} </mat-card-title>
        <mat-card-content> {{ messageContent }} </mat-card-content>
        <br>
        <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
            <button mat-raised-button color="warn" [routerLink]="['/plataforma-tutor']">OK</button>
        </div>
    </mat-card>
</div>
<div class="form-content" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="redactionCard">
        <mat-card-title>Corrigir estudo de caso - {{ currentUser }}</mat-card-title>
        <mat-card-content>
            <div>
                <mat-spinner  diameter="60" *ngIf="loading"></mat-spinner>
                {{ studyCaseContent }}
            </div>
            <br>
            <br>
            <div fxLayout="row">
                <span><strong>Nota: {{ grade }}</strong></span><br><br>
                <mat-slider color="primary" thumbLabel min="0" max="10" step="1" [(ngModel)]="grade" (blur)="saveCorrection(true)"></mat-slider><br>
                <span class="success" *ngIf="saveMessage"><strong>Suas alterações foram salvas!</strong></span>
            </div>
            <br>
            <div fxLayout="row" fxLayoutAlign="end" class="textAlignEnd">
                <button mat-button color="secondary" [routerLink]="['/plataforma-tutor']">Cancelar</button>
                <button mat-raised-button color="primary" (click)="saveCorrection(true)">Salvar</button>
                <mat-progress-bar *ngIf="requestionServer" mode="indeterminate"></mat-progress-bar>
            </div>
        </mat-card-content>
    </mat-card>
</div>