<h2>
    Registrar Desistência do Candidato
</h2>
<div class="container">
    <mat-form-field appearance="fill">
        <mat-label>Motivo da desistência</mat-label>
        <mat-select [(ngModel)]="giveUp.reason">
            <mat-option value="Problemas familiares">
			  Problemas familiares
			</mat-option>
			<mat-option value="Sem acesso à internet">
			  Sem acesso à internet
			</mat-option>
			<mat-option value="Trabalhando">
			  Trabalhando
			</mat-option>
			<mat-option value="Indolência">
			  Indolência
			</mat-option>
			<mat-option value="Desconfiança na instituição">
			  Desconfiança na instituição
			</mat-option>
			<mat-option value="Dificuldade de autopercepção">
			  Dificuldade de autopercepção
			</mat-option>
			<mat-option value="Estudando">
			  Estudando
			</mat-option>
			<mat-option value="Outros">
			  Outros
			</mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
        <mat-label>
            Detalhes
        </mat-label>
        <textarea matInput maxlength="150" [(ngModel)]="giveUp.detail"></textarea>
        <mat-hint align="end">{{ giveUp.detail.length }}/150</mat-hint>
    </mat-form-field>
    <br>
    <div class="btn-container">
        <button mat-stroked-button (click)="registerGiveUp()" [disabled]="loading">Salvar</button>
    </div>
    <br>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</div>
