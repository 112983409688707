export class User {
    firstName: string;
    lastName: string;
    email: string;
    checkEmail: string;
    whatsApp: string;
    password : string = "";
    rePassword: string;
}


