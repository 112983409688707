<div class="test-content" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
        <mat-card-title>Prova - Multipla escolha</mat-card-title>
        <br><br>
        <mat-card-title>Quem descobriu o Brasil?</mat-card-title>
        <mat-card-content>
            <mat-selection-list>
                <mat-list-option [id]="i" *ngFor="let criterio of criterios; let i = index" [attr.data-index]="i" color="primary">
                    {{ criterio }}
                </mat-list-option>
            </mat-selection-list>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="end" class="textAlignEnd">
                <button mat-raised-button color="primary">Enviar</button>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>