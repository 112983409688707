import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ResidentClass } from '../../Model/resident.class';
import { UserService } from '../../Services/user.service';
import { InterviewClass } from '../../Model/interview.class';
import {UserActivityService} from 'src/app/Services/user-activity.service';

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent implements OnInit {

  interview: InterviewClass = new InterviewClass();
  interviewPerguntas: InterviewClass = new InterviewClass();

  residents: Array<ResidentClass> = [];
  
  private token: string = sessionStorage.getItem('authToken');
  private guidInterviewer: string = ((new JwtHelperService).decodeToken(this.token)).guid;
  public currentUser = sessionStorage.getItem('currentUser');
  public fullNameTutor = sessionStorage.getItem('fullNameTutor');
  loading = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private activityService: UserActivityService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar) { }
    private idUser = Number(sessionStorage.getItem('idUser'));

  ngOnInit(): void { 

    this.userService.getInterview(this.token, this.route.snapshot.paramMap.get('guid'))
      .subscribe(
        result => {
          if (result) {
            this.residents = result.interview_json.residents;
            this.interview = result.interview_json.interview;
            this.interview.perguntas = this.interviewPerguntas.perguntas;
          }
        },
        error => {
          console.error(error);
        }
      )

    this.getUserActivity()
    this.interview.nome_candidato = this.currentUser;
    this.interview.nome_entrevistrador = this.fullNameTutor;
  }

  private async getUserActivity() {

    const activityData = {
      correction: [],
    };

    const otherActivity = await this.activityService.getCorrection(this.token, this.idUser).toPromise();

    const mercadoTrabalho = await this.activityService.getActivity(
      this.token,
      this.route.snapshot.paramMap.get('guid'),
      'mercado_de_trabalho')
      .toPromise();

    const raciLogico = await this.activityService.getActivity(
      this.token,
      this.route.snapshot.paramMap.get('guid'),
      'raciocinio_logico')
      .toPromise();

    const atualidades = await this.activityService.getActivity(
      this.token,
      this.route.snapshot.paramMap.get('guid'),
      'atualidades')
      .toPromise();

    activityData.correction.push(otherActivity)
    activityData.correction.push(mercadoTrabalho.result[0].numCorrectAns)
    activityData.correction.push(raciLogico.result[0].numCorrectAns)
    activityData.correction.push(atualidades.result[0].numCorrectAns)

    return activityData;

  }

  private frequencyOption(option: string, questionValue: number, reversed = false): number {

    const alternativeValue = questionValue / 5;

    switch (option) {
      case 'sempre':
        return (reversed ? (alternativeValue * 5) : (alternativeValue * 1));
      case 'frequentemente':
        return (reversed ? (alternativeValue * 4) : (alternativeValue * 2));
      case 'às vezes':
        return alternativeValue * 3;
      case 'quase nunca':
        return (reversed ? (alternativeValue * 2) : (alternativeValue * 4));
      case 'nunca':
        return (reversed ? (alternativeValue * 1) : (alternativeValue * 5));
      default:
        break;
    }
  }

  private ternaryOption(option: string, reversed = false): number {

    switch (option) {
      case 'sim':
        return (reversed ? 25 : 8.33);
      case 'não':
        return (reversed ? 8.33 : 25);
      case 'não sei dizer':
        return 16.66;
      default:
        break;
    }
  }

  private itemQuantity(option: string, questionValue: number, reversed = false): number {

    const alternativeValue = questionValue / 4;

    switch (option) {

      case '0':
        return (reversed ? alternativeValue * 4 : alternativeValue * 1);
      case '1':
        return (reversed ? alternativeValue * 3 : alternativeValue * 2);
      case '2':
        return (reversed ? alternativeValue * 2 : alternativeValue * 3);
      case '3':
        return (reversed ? alternativeValue * 1 : alternativeValue * 4);
    }

  }

  private somaPontos(obj) {
    let soma = 0;

    for (const el in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, el)) {
        soma += parseFloat(obj[el]);
      }
    }

    return soma;
  }

  async calculateScore(): Promise<number> {

    let rendaTotal = 0;
    let pontos_renda = 62.5;

    this.residents.forEach((resident) => {
      rendaTotal += Number(resident.renda) * 1000;
    });

    const rendaPerCapta = rendaTotal / this.residents.length;

    if(rendaPerCapta > 4000) {
      return 0;
    }

    if(rendaPerCapta <= 3000) {
      pontos_renda = 125;
    }

    if(rendaPerCapta <= 2000) {
      pontos_renda = 187.5;
    }

    if(rendaPerCapta <= 1000) {
      pontos_renda = 250;
    }

    const pontuacaoAtividades = await this.getUserActivity();

    const pontosRedacao = Number(pontuacaoAtividades.correction[0].historical_organization) * 1.25 +
    Number(pontuacaoAtividades.correction[0].job_focus_score) * 1.25 +
    Number(pontuacaoAtividades.correction[0].persistence_score) * 1.25 +
    Number(pontuacaoAtividades.correction[0].problem_resolution_score) * 1.25;

    const pontosEstudoCaso = Number(pontuacaoAtividades.correction[0].case_study_score) * 5;
    const pontosMultEscolha = pontuacaoAtividades.correction[1] * 4.167 +
    pontuacaoAtividades.correction[2] * 4.167 +
    pontuacaoAtividades.correction[3] * 4.167;

    const pontuacao = {

      desafios: (Number(this.interview.notas[0]) * 3.75),
      argumentacao: 22.5 - (3.75 * Number(this.interview.notas[1])),
      objetivo: (Number(this.interview.notas[2]) * 3.75),
      potencilidades: 22.5 - (3.75 * Number(this.interview.notas[3])),
      lazer: 22.5 - (3.75 * Number(this.interview.notas[4])),
      organizacao: 22.5 - (3.75 * Number(this.interview.notas[5])),
      vulnerabilidade: (Number(this.interview.notas[6]) * 3.75),
      autoEstima: 22.5 - (3.75 * Number(this.interview.notas[7])),
      satisfeito: this.frequencyOption(this.interview.respostas[8], 25),
      estressado: this.frequencyOption(this.interview.respostas[9], 25, true),
      triste: this.frequencyOption(this.interview.respostas[10], 25, true),
      ansioso: this.frequencyOption(this.interview.respostas[11], 25, true),
      doenca_psiq: this.ternaryOption(this.interview.respostas[12], true),
      doenca_familia: this.ternaryOption(this.interview.respostas[13], true),
      filhos: this.ternaryOption(this.interview.respostas[15]),
      vinculo_familiar: this.frequencyOption(this.interview.respostas[19], 15),
      amparo_familiar: this.frequencyOption(this.interview.respostas[20], 15),
      vinculo_amizades: this.frequencyOption(this.interview.respostas[21], 15),
      tarefas_domesticas: this.frequencyOption(this.interview.respostas[22], 15, true),
      alimentacao: this.frequencyOption(this.interview.respostas[23], 15),
      vulnerabilidade_social: this.frequencyOption(this.interview.respostas[24], 15, true),
      geladeira: this.itemQuantity(this.interview.respostas[30], 15, true),
      moradores_comodo: (Number(this.interview.respostas[43]) >= 3 ? 15 : 7.5),
      relatorio_social: (Number(this.interview.respostas[44]) * 10),
      relatorio_psicologico: (Number(this.interview.respostas[45]) * 10),
      pontos_renda,
      pontosRedacao,
      pontosEstudoCaso,
      pontosMultEscolha

    }

    return Number(this.somaPontos(pontuacao).toFixed(2));
  }

  showObject() {
    console.log(JSON.stringify(this.interview.perguntas));
  }

  addResidents() {

    let newResident = new ResidentClass();

    this.residents.push(newResident);
  }

  // Se finalSave for true -> Seta o status da entrevista na tabela para REALIZADA
  async saveInterview(finalSave?: boolean) {

    this.loading = true;
    let status = '';

    if(finalSave) {
      status = 'FINISHED'
    } else {
      status = 'STARTED'
    }
    let score: number;

    // Os dados da entrevista devem ser inseridos no campo interview_json
    const reqBody = {
      guid_interviewer: this.guidInterviewer,
      status,
      interview_json: {
        interview: this.interview,
        residents: this.residents
      }
    }

    setTimeout(() => {

      this.userService.saveInterview(
        this.token,
        this.route.snapshot.paramMap.get('guid'),
        reqBody
      ).subscribe(
        result => {
          this.loading = false;
          this.snackBar.open(result.message, 'Sucesso', {
            duration: 1500
          })
        },
        error => {
          console.log(error);
        }
      )
    }, 1000);
  }

}