<!-- warning text to confirm email -->
<div class="login-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="warningEmailShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <mat-card-content style='margin-top: 0 !important'>
                <p>Entre no seu e-mail cadastrado <strong>{{user.email}}</strong> para confirmar sua conta.</p>
                <p>Se esqueceu sua senha, clique em “Esqueci minha senha”.</p>
                <p>
                    Se não tiver recebido o e-mail de confirmação, 
                    <a (click)="SendEmail()">clique aqui</a> que a gente envia de novo para você.
                </p>
                <p>Se continuar com problemas, entre em contato com a gente!</p>
            </mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button mat-raised-button color="warn"
                    (click)='warningEmailShow = false'>
                    OK
                </button>
            </div>
        </mat-card>
    </div>
</div>

