import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../Model/user.class';
import { UserService } from '../../Services/user.service';
import { SHA1 } from 'crypto-js';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide: boolean = true;
  error: boolean = false;
  email: string;
  password: string;

  /* modal booleans */
  messageShow: boolean = false;
  warningEmailShow: boolean = false;

  /* modal messages */
  messageTitle: string;
  messageContent: string; 
  resultMessage: string = "";

  errorMessage: string; 
  emailForm: FormControl = new FormControl('', [Validators.required, Validators.email]);
  pwdForm:   FormControl = new FormControl('', [Validators.required]);

  userAuthenticated: Boolean = false;
  resultAuth: any;

  requestionServer: boolean = false;

  constructor( private router: Router,
    private user: User,
    private userService: UserService, private route: ActivatedRoute) { }
  
  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('tkn');
    this.confirmEmail(token);
  }

  confirmEmail(token: string) {
    if (token) {
      try {
        this.userService.verifyMailToken(token)
          .subscribe(
            result => {
              console.log(result);
              this.messageBox('E-mail confirmado!', 'Agora é só seguir para a segunda etapa do processo classificatório do NAU.');
              return sessionStorage.setItem('subscriptionValid', 'true');
            },
            error => {
              console.log(error);
              if(error.status == 401) {
                this.messageBox('Ops!', 'Seu link de confirmação de cadastro é inválido!');
              }
              else if (error.status == 400) {
                this.messageBox('Ops!', error.error.message)
              }
            }
          )
      } catch (error) {
        this.messageBox('Ops!', error.message)
        console.log(error);
      }
    }
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }
  /* used to generic messages of modal */
   messageBox(title: string, content: string): void {
    if (title == "E-mail confirmado!") {
      this.toogleMessageShow(true);
      this.resultMessage =`Você já pode fazer o login :)`
    }
    else {
      this.toogleMessageShow(true);
    }
      this.messageTitle = title;
      this.messageContent = content;
      window.scrollTo(0, 0);
  }

  /* used to confirm email modal */
  SpecialMessageBox(title: string, content: string): void {
    if (title == 'Aviso') {
      this.warningEmailShow = true;
    }
    this.messageTitle = title;
    this.messageContent = content;
    window.scrollTo(0, 0);
  }

  messageParagraph(content: string): void {
    this.errorMessage = content;
    this.error = true;
  }

  formatEmail(email: string): void {
    this.email = email.toLowerCase();
  }

  getEmailErrorMessage(): string {
    let msg: string = ""
    if ( this.emailForm.hasError('required') ) {
      msg =  "Você precisa digitar um e-mail";
    }
    else if ( this.emailForm.hasError('email') ) {
      msg =  "Você não digitou um e-mail válido!";
    }
    return msg;
  }

  getPwdErrorMessage(): string {
    let msg: string = "";
    if ( this.pwdForm.hasError('required') ) {
      msg = "Você precisa digitar uma senha";
    }
    return msg;
  }

  accountCreateuser() {
     this.router.navigate(['/nova-conta']);
  }

  forgotAccount() {
    this.router.navigate(['/recuperacao-senha']);
  }

  userAuth() {
    const jwtHelper = new JwtHelperService();
    this.user.email = this.email;
    this.user.password = SHA1(this.password).toString();
    this.requestionServer = true;
    this.userService.loginUser(this.user)
      .subscribe(
        result => {
          this.resultAuth = result;
          if(result.code == 1) {
            this.requestionServer = false;
            sessionStorage.setItem('authToken', result.token);

            const jwtToken = sessionStorage.getItem('authToken');
            const userProfile = (jwtHelper.decodeToken(jwtToken)).profile;
            const idUser = (jwtHelper.decodeToken(jwtToken)).id_user;
            const fullNameTutor = (jwtHelper.decodeToken(jwtToken)).first_name + " " +
                                  (jwtHelper.decodeToken(jwtToken)).last_name;

            if(userProfile != "CANDIDATO" && userProfile != null) {
              sessionStorage.setItem('fullNameTutor', fullNameTutor);
              return this.router.navigate(['plataforma-tutor']);
            }

            this.userService.getSubscriptionStatus(jwtToken)
              .subscribe(
                result => {
                  if(result.is_register_valid == true) {
                    sessionStorage.removeItem('currentUser');
                    sessionStorage.setItem('idUser', idUser);
                    return this.router.navigate(['plataforma-candidato']);
                  }
                    return this.router.navigate(['/inscricao']);
                },
                error => {
                  this.messageBox("Erro!", 'Houve um erro ao processar sua requisição!');
                  console.log(error);
                }
              )
          }
        }, failure => {
          /* let loginFail = Number(sessionStorage.getItem('loginFail'));
          sessionStorage.setItem('loginFail', ((loginFail) + 1).toString()) */
          if (failure.status == 403) {
            this.SpecialMessageBox('Aviso', 'Confira seu e-mail para confirmar seu cadastro.');
          }
          else if (failure.error.code == 3) {
            this.messageBox("Ops!", failure.error.message);
          }
          else if (failure.error.code == 2) {
            this.messageBox("Ops!", failure.error.message)
          }
          else {
            this.messageBox("Ops!", "Não foi possivel se comunicar com o servidor");
          }
          return this.requestionServer = false;
        }
      );

  }
  
}