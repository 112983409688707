import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PasswordRecoveryService } from 'src/app/Services/password-recovery.service';
import { SHA1 } from 'crypto-js';
import { Email } from 'src/app/Model/e-mail.class';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {
   /* models */
  recoveryEmail: Email = new Email;

  form: FormGroup;

  /* messages and forms */
  email: string;
  messageTitle: string;
  messageContent: string;

  /* options */
  messageShow: boolean = false;

  /* user: {
    email: '',
    password: ''
    passowrd_confirm: '';
  } */

  constructor(
    private router: Router, 
    private passwordRecoveryService : PasswordRecoveryService,
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute)  {
      this.form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: [''],
        password_confirm: [''],
      });
  }
  
  resultRequest : any;
  
  requestionServer: boolean = false;

  ngOnInit(): void { }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  formatEmail(email: string): void {
    this.email = email.toLowerCase();
  }

  getEmailErrorMessage(erro?: string): string {
    let msg: string = ""
    if (this.form.value.email == undefined) {
      msg =  "Você precisa digitar um e-mail";
    }
    else if (this.form.value.email == '') {
      msg =  "Você precisa digitar um e-mail";
    }
    else if ( this.form.controls.email.hasError('email') &&
    this.form.value.email !== undefined || "") {
      msg =  "Você precisa digitar um e-mail válido";
    }
    else if (erro == "erro_email") {
      msg =  "Email não encontrado no Banco de Dados!";
    }
    return msg;
  }

  pwdRecovery() {
    this.requestionServer = true;
    let requestBody = { email: this.form.value.email };
    this.passwordRecoveryService.recovery(requestBody)
    .subscribe(
      result => {
        this.resultRequest = result;
        this.messageBox("Sucesso!", "Foi enviado um e-mail com instruções para recuperação de sua conta!");
        this.requestionServer = false;
      },
      error => {
        this.requestionServer = false;
        this.messageBox("Ops!", `Parece que esse e-mail não está cadastrado com a gente. 
        Veja se escreveu certo o e-mail utilizado no seu cadastro.`);
        this.requestionServer = false;
      }
    );
  }
}
