import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/Services/user.service';
import { User } from 'src/app/Model/user.class';

@Component({
  selector: 'app-user-alert',
  templateUrl: './user-alert.component.html',
  styleUrls: ['./user-alert.component.css']
})
export class UserAlertComponent implements OnInit {

  constructor(
    private userService: UserService,
    public user: User

  ) { }


  @Input() messageTitle: string = "";
  @Input() messageContent: string = "";
  @Input() resultMessage: string = ``;

  @Input() warningEmailShow: boolean = false;

  ngOnInit(): void {
  }

  toogleMessageShow(card: boolean): void {
    card = false;
  }

  SendEmail() {
    let requestBody = {
      email: this.user.email
    }
    this.warningEmailShow = false;
    this.userService.sendValidationMail(requestBody)
      .subscribe(
        result => {
          this.warningEmailShow = false;
        }
      )
  }
}
