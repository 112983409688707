<div class="register-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button *ngIf="!accountCreated" mat-raised-button color="warn"
                    (click)="toogleMessageShow(false);">OK</button>
                <button *ngIf="accountCreated" mat-raised-button color="warn" [routerLink]="['/login']">OK</button>
            </div>
        </mat-card>
    </div>
    <mat-card>
        <mat-card-title>
            Criar usuário
        </mat-card-title>
        <mat-card-content>
            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Nome</mat-label>
                <input matInput type="text" [(ngModel)]="userFirstName" />
            </mat-form-field>

            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Sobrenome</mat-label>
                <input matInput type="text" [(ngModel)]="userLastName" />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label [ngClass]="{ 'email.valid': 'colorlightgreen'}">E-mail</mat-label>
                <input matInput type="email" [formControl]="emailForm" [(ngModel)]="email"
                    (keyup)="formatEmail(email)" />
                <mat-icon matSuffix *ngIf="emailForm.valid" [ngClass]="{'emailForm.valid': 'colorlightgreen' }">checked
                </mat-icon>
                <mat-hint *ngIf='email === undefined'>Exemplo: contato@nau.com.br</mat-hint>
                <mat-error *ngIf="emailForm.invalid">{{ getEmailErrorMessage() }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>CPF</mat-label>
                <input #inputCPF matInput type="text" name="cpf" [(ngModel)]="userCPF" (keyup)="formatCPF(this.userCPF)"
                    (blur)="getCpfErrorMessage()" />
                <mat-hint *ngIf="!cpfValid">Este número de CPF não é valido</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Telefone (com DDD)</mat-label>
                <input matInput input.mat-input-element.my-custom-class [(ngModel)]="userPhone" type="text" />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Escolha uma data</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="userBirthDay">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Perfil de usuário</mat-label>
                <mat-select [(ngModel)]="userProfile">
                    <mat-option *ngFor="let userType of userTypes" [value]="userType.value">
                        {{ userType.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Senha</mat-label>
                <input matInput input.mat-input-element.my-custom-class [type]="hide ? 'password' : 'text'"
                    [formControl]="pwdForm" [(ngModel)]="password" />
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-hint *ngIf='password === undefined'>
                    A senha precisa ter mais que 6 caracteres
                </mat-hint>
                <mat-error *ngIf="pwdForm.invalid">{{ getPwdErrorMessage() }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Confirme a senha</mat-label>
                <input matInput input.mat-input-element.my-custom-class [type]="hideCheckedPwd ? 'password' : 'text'"
                    [(ngModel)]="rePassword" />
                <button mat-icon-button matSuffix (click)="hideCheckedPwd = !hideCheckedPwd"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideCheckedPwd">
                    <mat-icon>{{hideCheckedPwd ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            </mat-card-content>

            <mat-card-footer>
                <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd">
                    <button mat-raised-button [routerLink]="['/login']">Voltar</button>
                    <button
                        [disabled]="!(emailForm.valid && pwdForm.valid && passwordCheck.valid && isCpfValid(userCPF) )"
                        mat-raised-button color="primary" (click)="userRegister()">Criar Conta</button>
                    <mat-progress-bar *ngIf="requestionServer" mode="indeterminate"></mat-progress-bar>
                </div>
            </mat-card-footer>
    </mat-card>
    <br>
    <br>
    <br>
</div>