<div class="register-confirmation-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button mat-raised-button color="warn" (click)='toogleMessageShow(false)'>OK</button>
            </div>
        </mat-card>
        <br>
    </div>

    <mat-card>
        <mat-card-title>Confirmação - Dados Pessoais</mat-card-title>
        <mat-card-content>
            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Nome</mat-label>
                <input matInput type="text" name="name" [(ngModel)]="userFirstName" />
            </mat-form-field>

            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Sobrenome</mat-label>
                <input matInput type="text" name="name" [(ngModel)]="userLastName" />
            </mat-form-field>
            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>CPF</mat-label>
                <input matInput [(ngModel)]="userCPF" (keyup)="formatCPF(this.userCPF)" (change)="isCpfValid(this.userCPF)">
            </mat-form-field>
            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Telefone (com DDD)</mat-label>
                <input matInput [(ngModel)]="userPhone">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Escolha uma data</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="userBirthDay">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <br>

    <mat-card>
        <mat-card-title>Confirmações</mat-card-title>
        <mat-card-content>
            <app-list-section-warning></app-list-section-warning>
        </mat-card-content>
    </mat-card>
    <br>

    <mat-card>
        <mat-card-title>Conheça o NAU!</mat-card-title>
        <mat-card-content>
            <p>Nós somos um projeto social do Instituto SEB que promove o desenvolvimento de jovens como você, que estão querendo entrar no mercado de trabalho.</p>
            <br>
            <p>Todo semestre nós abrimos um processo seletivo para quem tiver interesse em se preparar para o mundo do trabalho e se encaixe nesse perfil:</p>
            <br>
            <p>- Ter entre 17 a 24 anos;</p>
            <p>- Estar cursando o 3º ano do Ensino Médio ou ter concluído o Ensino Médio;</p>
            <p>- Ter como renda familiar (soma da renda das pessoas que residem com você) até 4 salários mínimos (R$4.848,00);</p>
            <p>- Estar desempregado.</p>
            <br>
            <p>Se você tem esse perfil, o NAU é para você!</p>
            <p>O treinamento tem duração de 3 meses e acontece online três vezes na semana. Além disso, você terá uma trilha de aprendizagem em uma plataforma gamificada e super interativa!</p>
            <br>            
            <p>Vamos lá?</p>
        </mat-card-content>
    </mat-card>
    <br>

    <mat-card>
        <mat-card-title>Confirmar inscrição</mat-card-title>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignCenter">
                <button mat-raised-button color="warn" [routerLink]="['/fique-conosco']">Cancelar</button>
                <button mat-raised-button color="primary" (click)="sendForm()">Confirmar</button>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <br>
    <br>
</div>