import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit(): void {
  }

  userTypes = [
    { label: 'Administrador', value: 1 },
    { label: 'Tutor', value: 2 },
    { label: 'Psicologo', value: 4 }
  ]

  hide: boolean = true;
  hideCheckedPwd = true;
  hidecheckedpwd: boolean = true;

  userFirstName : string;
  userLastName : string;
  userCPF : string; 
  email: string;
  userPhone: string = "";
  userBirthDay: Date;
  userProfile: number;
  password: string;
  rePassword: string;

  messageShow: boolean = false;
  messageTitle: string;
  messageContent: string; 

  modalShow: boolean = false;
  modalTitle: string;
  modalContent: string;

  accountCreated: boolean = false;

  resultAuth : any;
  requestionServer:boolean = false;

  emailForm: FormControl = new FormControl('', [ Validators.required, Validators.email ]);
  pwdForm:   FormControl = new FormControl('', [ Validators.required, Validators.minLength(6) ]);
  passwordCheck: FormControl = new FormControl(['', Validators.required, Validators.minLength(6)]);

  cpfValid: boolean = false;

  toggleModalShow(show: boolean): void {
    this.modalShow = show;
  }

  modal(title: string, content: string): void {
    this.modalTitle = title;
    this.modalContent = content;
    this.toggleModalShow(true);
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  formatEmail(email: string): void {
    if(email != undefined) {
      this.email = email.toLowerCase();
    }
  }

  formatCPF(cpf){
    let newCPF = cpf;
    newCPF = cpf.replace(/[^0-9]/g,"");
    if ( newCPF.length < 4 )
      this.userCPF = newCPF;
    else if ( newCPF.length < 7 )
      this.userCPF = newCPF.substring(0,3) + '.' + newCPF.substring(3,6);
    else if ( newCPF.length < 10 )
      this.userCPF = newCPF.substring(0,3) + '.' + newCPF.substring(3,6) + '.' + newCPF.substring(6,9);
    else if ( newCPF.length < 12 )
      this.userCPF = newCPF.substring(0,3) + '.' + newCPF.substring(3,6) + '.' + newCPF.substring(6,9) + '-' + newCPF.substring(9,11);
    else
      this.userCPF = newCPF.substring(0,3) + '.' + newCPF.substring(3,6) + '.' + newCPF.substring(6,9) + '-' + newCPF.substring(9,11);
  }

  isCpfValid(cpf): boolean {
    let result = false;
    let newCPF = cpf;
    let digitoVerificador1: number = 0;
    let digitoVerificador2: number = 0;

    newCPF = cpf.replace(/[^0-9]/g,"");
    let numbersOfCpf = newCPF.split('');

    digitoVerificador1 = (
      ( 
        ( 
          ( numbersOfCpf[0]  * 10 ) + 
          ( numbersOfCpf[1]  *  9 ) +
          ( numbersOfCpf[2]  *  8 ) +
          ( numbersOfCpf[3]  *  7 ) +
          ( numbersOfCpf[4]  *  6 ) +
          ( numbersOfCpf[5]  *  5 ) +
          ( numbersOfCpf[6]  *  4 ) +
          ( numbersOfCpf[7]  *  3 ) +
          ( numbersOfCpf[8]  *  2 ) 
        ) * 10 
      ) % 11
    )

    digitoVerificador2 = (
      ( 
        (
          ( numbersOfCpf[0]  * 11 ) + 
          ( numbersOfCpf[1]  * 10 ) +
          ( numbersOfCpf[2]  *  9 ) +
          ( numbersOfCpf[3]  *  8 ) +
          ( numbersOfCpf[4]  *  7 ) +
          ( numbersOfCpf[5]  *  6 ) +
          ( numbersOfCpf[6]  *  5 ) +
          ( numbersOfCpf[7]  *  4 ) +
          ( numbersOfCpf[8]  *  3 ) +
          ( numbersOfCpf[9]  *  2 ) 
        ) * 10
      ) % 11
    )

    if ( digitoVerificador1 === 10 ) digitoVerificador1 = 0;
    if ( digitoVerificador2 === 10 ) digitoVerificador2 = 0;

    if ( 
      numbersOfCpf[0] === numbersOfCpf[1] &&
      numbersOfCpf[1] === numbersOfCpf[2] &&
      numbersOfCpf[2] === numbersOfCpf[3] &&
      numbersOfCpf[3] === numbersOfCpf[4] &&
      numbersOfCpf[4] === numbersOfCpf[5] &&
      numbersOfCpf[5] === numbersOfCpf[6] &&
      numbersOfCpf[6] === numbersOfCpf[7] &&
      numbersOfCpf[7] === numbersOfCpf[8] &&
      numbersOfCpf[8] === numbersOfCpf[9] ) { 

        result = false; 
    }
    else if ( digitoVerificador1 == numbersOfCpf[9] && digitoVerificador2 == numbersOfCpf[10] ) {
        result = true;
        this.cpfValid = true;
    }
    else
    {
      this.cpfValid = false;
      result = false;
    }
    return result;
  }

  getCpfErrorMessage(): void {
    if(!this.isCpfValid(this.userCPF)) {
      this.messageBox('Erro', 'Este CPF não é válido!');
    }
  }

  getEmailErrorMessage(): string {
    let msg: string = ""
    if ( this.emailForm.hasError('required') ) {
      msg =  "Você precisa digitar um e-mail";
    }
    else if ( this.emailForm.hasError('email') ) {
      msg =  "Você não digitou um e-mail válido!";
    }
    return msg;
  }

  getPwdErrorMessage(): string {
    let msg: string = "";
    if ( this.pwdForm.hasError('required') ) {
      msg = "Você precisa digitar uma senha";
    }
    else if ( this.pwdForm.errors.minlength.actualLength < 6) {
      msg =  "A senha que você digitou tem menos que 6 caracteres";
    }
    return msg;
  }

  isFieldsPasswordEquals(): boolean {
    if ( this.password ===  this.rePassword ) 
      return true;
    else 
      return false;
  }

  checkInputs(): boolean{
    if ( this.userFirstName === "" )
      return false;
    else if ( this.userLastName === "")
      return false;
    else if ( this.userCPF === "")
      return false;
    else if ( this.email === "")
      return false;
    else
      return true;
  }

  userRegister() {
    if (this.checkInputs && this.isFieldsPasswordEquals()) {

      const tempCPF = this.userCPF.replace(/[^0-9]/g,"")
      const tempPhone = this.userPhone.replace(/[^\d]/g, '');

      const userObj = {
        first_name: this.userFirstName,
        last_name: this.userLastName,
        email: this.email,
        cpf: tempCPF,
        password: this.password,
        birth_date: this.userBirthDay,
        phone_number: tempPhone,
        id_profile: this.userProfile
      }

      this.requestionServer = true;
  
      this.userService.createParticularUser(userObj)
        .subscribe(
          result => {
            this.messageBox('Sucesso', 'Usuário cadastrado com sucesso!');
            this.accountCreated = true;
          },
          failure => {
            if(failure.status == 409) {
              this.messageBox('Erro', failure.error.message);
            }
            this.requestionServer = false;
          }
        );
      }
    else {
      this.messageBox('Erro', 'As senhas não conferem!');
    }
  }

}
