import { BrowserModule } from '@angular/platform-browser';
import { Component, NgModule } from '@angular/core';
import { AngularModule } from './angular.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormComponent } from './Components/form/form.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './Pages/home/home.component';
import { NavbarComponent } from './Components/navbar/navbar.component';
import { ToolbarComponent } from './Components/toolbar/toolbar.component';
import { FooterComponent } from './Components/footer/footer.component';
import { LoginComponent } from './Components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './Components/register/register.component';
import { ListSectionsWarning, RegisterConfirmationComponent } from './Components/register-confirmation/register-confirmation.component';
import { PasswordRecoveryComponent } from './Components/password-recovery/password-recovery.component';
import { TestComponent } from './Components/test/test.component';
import { StayWithUsComponent } from './Components/stay-with-us/stay-with-us.component';
import { CandidatePlataformComponent } from './plataform-candidate/home/candidate-plataform.component';
import { CreateAccountComponent } from './Components/create-account/create-account.component';
import { GoodbyeComponent } from './Components/goodbye/goodbye.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserService } from './Services/user.service';
import { User } from './Model/user.class';
import { PasswordRecoveryService } from './Services/password-recovery.service';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { Email } from './Model/e-mail.class';
import { RegistrationCompletedComponent } from './Components/registration-completed/registration-completed.component';
import { DocumentsComponent } from './Components/documents/documents.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { RedactionComponent } from './plataform-tutor/redaction/redaction.component';
import { CreateUserComponent } from './plataform-tutor/create-user/create-user.component';
import { PlataformTutorComponent } from './plataform-tutor/home/plataform-tutor.component';
import { QuizComponent } from './plataform-tutor/quiz/quiz.component';
import { StudyCaseComponent } from './plataform-tutor/study-case/study-case.component';
import { DocumentUploadComponent } from './plataform-tutor/document-upload/document-upload.component';
import { UserInfoComponent } from './plataform-tutor/user-info/user-info.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPortuguesePaginatorIntl } from './Constants/portuguese-paginator-intl';
import { NewPasswordComponent } from './Components/new-password/new-password.component';
import { InterviewComponent } from './plataform-tutor/interview/interview.component';
import { GiveUpComponent } from './plataform-tutor/give-up/give-up.component';
import { APP_BASE_HREF } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserAlertComponent } from './Components/alerts/user-alert/user-alert.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    HomeComponent,
    NavbarComponent,
    ToolbarComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    RegisterConfirmationComponent,
    ListSectionsWarning,
    PasswordRecoveryComponent,
    TestComponent,
    StayWithUsComponent,
    CandidatePlataformComponent,
    CreateAccountComponent,
    GoodbyeComponent,
    RegistrationCompletedComponent,
    DocumentsComponent,
    RedactionComponent,
    CreateUserComponent,
    PlataformTutorComponent,
    QuizComponent,
    StudyCaseComponent,
    DocumentUploadComponent,
    UserInfoComponent,
    NewPasswordComponent,
    InterviewComponent,
    GiveUpComponent,
    UserAlertComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AngularModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    PdfViewerModule,
    RouterModule
  ],
  providers: [
    UserService, 
    User,
    Email, 
    PasswordRecoveryService,
    { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    { provide: APP_BASE_HREF, useValue: '/'}
  ],
  bootstrap: [AppComponent]
  })
export class AppModule { }
