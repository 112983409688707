<div class="stay-with-us-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <br>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button *ngIf="!accountUpdated" mat-raised-button color="warn" [routerLink]="['/inscricao']">OK</button>
                <button *ngIf="accountUpdated" mat-raised-button color="warn" (click)='Redirect()'>OK</button>
            </div>
        </mat-card>
    </div>
    <br>
    <mat-card>
        <mat-card-title style="text-align: center;">
            Tudo bem não querer se inscrever agora!
        </mat-card-title>
        <mat-card-content align="center">
            <div fxLayout="row" fxLayoutAlign="space-around center" aling="" center>
                <!-- <iframe width="560" height="315"
                    src="https://www.youtube.com/embed/kDVJL1rUjWM?autoplay=1"
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe> -->
            </div>
            <p style="text-align: center; margin-bottom: 2rem;">
                Sua conta foi criada e você pode sempre voltar aqui 
                se mudar de ideia e quiser fazer parte da turma do Projeto NAU.
            </p>
            <!-- <h2 style="text-align: center;">Você quer realmente desistir?</h2> -->
        </mat-card-content>
        <mat-card-actions align="start">
            <div class='row'>
                <div class='column'>
                    <h4 class='button-title'>
                        Pensando bem, quero me inscrever sim!
                    </h4>
                    <button mat-button mat-raised-button color="primary"
                        (click)="sendForm()"
                        >
                        Clique Aqui
                    </button>
                </div>
                <div class='column'>
                    <h4 class='button-title'>
                        Quero parar por aqui e voltar numa próxima
                    </h4>
                    <button mat-button mat-raised-button color="warn"
                    [routerLink]="['/ate-mais']"
                    (click)="userAuthExit()">
                        Clique Aqui
                    </button>
                </div>
            </div>
        </mat-card-actions>
       <!--  <mat-card-actions align='end'>
        </mat-card-actions> -->
    </mat-card>

</div>