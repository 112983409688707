<div class="documents-content" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
        <mat-card-title>Upload de Arquivos</mat-card-title>
        <mat-card-content>

            <div class="file-input-wrapper">
                <label for="rgOk"><strong>RG </strong>{{ this.rgOk }}</label><br>
                <input #rgInput type="file" id="rg" [(ngModel)]="rgOk" hidden (change)="handleFileInput($event.target.files)"/>
                <button mat-stroked-button color="primary" (click)="rgInput.click()">
                    <mat-icon>upload</mat-icon>
                    Selecionar arquivo
                </button>
                <button mat-raised-button color="primary" sytle="margin:auto;" (click)="uploadFile('rg')">Enviar</button>
            </div>

            <div class="file-input-wrapper">
                <label for="cpfOk"><strong>CPF </strong>{{ this.cpfOk }}</label><br>
                <input #cpfInput type="file" id="cpf" [(ngModel)]="cpfOk" hidden hidden (change)="handleFileInput($event.target.files)"/>
                <button mat-stroked-button color="primary" (click)="cpfInput.click()">
                    <mat-icon>upload</mat-icon>
                    Selecionar arquivo
                </button>
                <button mat-raised-button color="primary" sytle="margin:auto;" (click)="uploadFile('cpf')">Enviar</button>
            </div>

            <div class="file-input-wrapper">
                <label for="certNascOk"><strong>Certidão de Nascimento </strong>{{ this.certNascOk }}</label><br>
                <input #certNascInput type="file" id="certNasc" [(ngModel)]="certNascOk" hidden hidden (change)="handleFileInput($event.target.files)"/>
                <button mat-stroked-button color="primary" (click)="certNascInput.click()">
                    <mat-icon>upload</mat-icon>
                    Selecionar arquivo
                </button>
                <button mat-raised-button color="primary" sytle="margin:auto;" (click)="uploadFile('certidao-nascimento')">Enviar</button>
            </div>

            <div class="file-input-wrapper">
                <label for="cmpResidOk"><strong>Comprovante de Residência </strong>{{ this.cmpResidOk }}</label><br>
                <input #cmpResidInput type="file" id="cmpResid" [(ngModel)]="cmpResidOk" hidden hidden (change)="handleFileInput($event.target.files)"/>
                <button mat-stroked-button color="primary" (click)="cmpResidInput.click()">
                    <mat-icon>upload</mat-icon>
                    Selecionar arquivo
                </button>
                <button mat-raised-button color="primary" sytle="margin:auto;" (click)="uploadFile('comprovante-residencia')">Enviar</button>
            </div>

            <div class="file-input-wrapper">
                <label for="cmpRendaOk"><strong>Comprovante de Renda </strong>{{ this.cmpRendaOk }}</label><br>
                <input #cmpRendaInput type="file" id="cmpRenda" [(ngModel)]="cmpRendaOk" hidden hidden (change)="handleFileInput($event.target.files)"/>
                <button mat-stroked-button color="primary" (click)="cmpRendaInput.click()">
                    <mat-icon>upload</mat-icon>
                    Selecionar arquivo
                </button>
                <button mat-raised-button color="primary" sytle="margin:auto;" (click)="uploadFile('comprovante-renda')">Enviar</button>
            </div>
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </mat-card-content>
    </mat-card>
    <br>

</div>