import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  logoNau: string = "../../../assets/img/LOGO_NAU_MERCADO_DE_TRABALHO.webp";
  homeLink: string = "/";

  constructor() { }

  ngOnInit(): void {
  }

}
