<div class="password-recovery-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button mat-raised-button color="warn" 
                    *ngIf="!recovered"
                    (click)='toogleMessageShow(false)'>
                    OK
                </button>
                <button mat-raised-button color="warn" 
                    *ngIf="recovered"
                    [routerLink]="['/login']">
                    OK
                </button>
            </div>
        </mat-card>
    </div>
    <br>
    <mat-card>
        <mat-card-title>Recuperar senha</mat-card-title>
        <mat-card-content>
            <form [formGroup]='form'>
                <mat-form-field appearance="fill">
                    <mat-label>Digite uma nova senha: </mat-label>
                    <input matInput
                        formControlName='password'
                        [type]="hide ? 'password' : 'text'"
                        [(ngModel)]="recoveryPassword.password"
                    />
                    <button mat-icon-button matSuffix
                        (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                    <mat-error *ngIf='form.status == "INVALID"'>
                        {{verifyFields()}}
                    </mat-error>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill">
                    <mat-label>Confirme sua senha: </mat-label>
                    <input
                        matInput
                        [type]="hideCheckedPwd ? 'password' : 'text'"
                        formControlName='password_confirm'
                        [(ngModel)]="recoveryPassword.password_confirm"
                    />
                    <button mat-icon-button matSuffix
                        (click)="hideCheckedPwd = !hideCheckedPwd"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideCheckedPwd">
                        <mat-icon>{{hideCheckedPwd ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="form.value.password_confirm == '' || undefined">
                        Digite a mesma senha acima
                    </mat-error>
                    <mat-error
                        *ngIf="form.get('password_confirm').hasError('invalidPasswords')">
                        As senhas não conferem
                    </mat-error>
                </mat-form-field>
            </form>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd">
                <button mat-raised-button [routerLink]="['/login']">Voltar</button>
                <button
                    [disabled]="this.form.status == 'INVALID' "
                    mat-raised-button color="primary"
                    (click)='newPassword()'>
                        Salvar Senha
                </button>
                <mat-progress-bar *ngIf="requestionServer" mode="indeterminate"></mat-progress-bar>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
</div>