import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor() { }

  public dialog = new BehaviorSubject<object>({});

  changeDialog(value: object): void {
    this.dialog.next(value);
  }

  resetDialog () {
    this.dialog.next({});
  }
}
