import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserActivityService } from 'src/app/Services/user-activity.service';
import { UserService } from 'src/app/Services/user.service';
import { TrilhaServices } from '../../Services/trilha.service';
@Component({
  selector: 'app-candidate-plataform',
  templateUrl: './candidate-plataform.component.html',
  styleUrls: ['./candidate-plataform.component.css']
})
export class CandidatePlataformComponent implements OnInit {

  messageTitle: string = "";
  messageContent: string = "";
  messageShow: boolean = false;
  activityReleased: boolean = false;
  interviewReleased: boolean = false;

  multipleChoice: string = "";
  redaction: string = "";
  studyCase: string = "";
  interview: string = "";

  multipleChoiceClass: string = "not-released";
  redactionClass: string = "not-released";
  studyCaseClass: string = "not-released";
  interviewClass:string = "not-released";

  private token: string = sessionStorage.getItem('authToken');

  multipleChoiceFinished:boolean = false;

  constructor(
    private userService: UserService,
    private userActivityService: UserActivityService,
    private router: Router
  ) { }

  ngOnInit(): void {

    const jwt = new JwtHelperService();
    const guid = (jwt.decodeToken(this.token)).guid;
    const id_user = (jwt.decodeToken(this.token)).id_user;
    const profile = (jwt.decodeToken(this.token)).profile;

    if(profile != 'CANDIDATO' && profile != null) {
      alert('Você não tem autorização para acessar essa página!');
      this.router.navigate(['/login']);
    }

    try {

      this.userService.getUserData(this.token, guid)
      .subscribe(
        result => {

          this.activityReleased = result.data.is_active;
          this.multipleChoiceClass = 'released';
          this.redactionClass= 'released';
          this.studyCaseClass = 'released';

          if(this.activityReleased) {
            this.userActivityService.getActivitiesStatus(this.token, id_user)
            .subscribe(
              result => {

                if(result.multiple_choice == 'FINISHED'){
                  this.multipleChoice = 'Atividade já realizada';
                  this.multipleChoiceClass = 'realized'
                } else {
                  this.multipleChoice = 'Atividade liberada';
                }

                if(result.redaction == 'FINISHED'){
                  this.redaction = 'Atividade já realizada';
                  this.redactionClass = 'realized'
                  this.interview = "Atividade liberada";
                  this.interviewClass = "released"
                  this.interviewReleased = true;
                } else {
                  this.redaction = 'Atividade liberada';
                  this.interviewReleased = false;
                }

                if(result.study_case == 'FINISHED'){
                  this.studyCase = 'Atividade já realizada';
                  this.studyCaseClass = 'realized'
                } else {
                  this.studyCase = 'Atividade liberada';
                }

              },
              error => {
                console.warn(error);
              }
            )
          }
        },
        error => {
          console.warn(error);
        }
      )

    } catch(error) {
      console.warn(error);
    }


  }

  logOut() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  toogleMessageShow(show: boolean) {
    this.messageShow = show;
  }
}