<div class="good-bye-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button mat-raised-button color="warn" (click)="toogleMessageShow(false);">OK</button>
            </div>
        </mat-card>
    </div>

    <mat-card>
        <mat-card-title>Espere . . . </mat-card-title>
        <mat-card-content>
            <p>Antes de você ir, queremos que você leve uma coisa com você:</p>
            <p>Nosso <a href="https://drive.google.com/file/d/1z9YvmOH6c1XiNvxF-07JA9zdcOjnyAuu/view?usp=sharing" target="_blank">Manual do Candidato!</a></p>
            <br>
            <p>Desejamos que você se realize, não importa o caminho que escolha!</p>
            <p>Por isso, leve esse Manual com você para descobrir dicas e links muito úteis para o seu caminho rumo ao mercado de trabalho.</p>
            <p>Nas nossas redes sociais temos mais conteúdos relacionados ao mercado de trabalho e também outras coisas legais para a galera jovem!</p>
            <br>
            <p>Dá uma olhada lá também! para as <a href="https://linktr.ee/projetonau" target="_blank">redes sociais do NAU</a></p>
            <br>
            <p>Então é isso!</p>
            <br>
            <p>Esperamos que você tenha muito sucesso nos caminhos que escolher e que continue na busca pelo emprego que deseja.</p>
            <p>E quando surgir interesse em nosso projeto futuramente, o convite para participar das inscrições para próxima turma está feito, tá?</p>
            <br>
            <p>Até uma próxima e sucesso no caminho!</p>
            <br>
        </mat-card-content>
    </mat-card>
</div>