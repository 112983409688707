<div fxLayout="row  wrap" fxLayoutAlign="end end" class="textAlignEnd">
    <p>Processo Classificatório : <button mat-raised-button>Em andamento</button></p>
    <div class='logOut'>
        <a title="Sair" (click)="logOut()" mat-icon-button color="primary">
            <mat-icon class='exit-icon'>exit_to_app</mat-icon>
        </a>
    </div>
</div>
<div class='container-row' fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div class='row-column'>
        <div class='column'>
            <h1 class='title'>Agora Falta pouco!</h1>
            <h2 class='title'>Para finalizar sua inscrição, clique na trilha de desafios!</h2>
        </div>
        <div class="div-steps">
            <mat-card class="card">
                <mat-card-header>
                    <mat-card-title>Me conte sobre você</mat-card-title>
                </mat-card-header>
                <mat-icon class="icone">list_alt</mat-icon>
                <mat-card-actions>
                    <div fxLayout="row" fxLayoutAlign="center" class="textAlignEnd">
                        <a mat-raised-button color="primary" href="https://lab.naumatch.com.br/#/"
                        [disabled]="!activityReleased || (this.redaction == 'Atividade já realizada')"
                        target="_blank">Fazer</a>
                    </div>
                </mat-card-actions>
            </mat-card>
            <!-- mat-card [class]="this.redactionClass">
                <mat-card-content class='activity-card'>
                    <p>
                        <strong>
                            {{ !activityReleased? 'Aguardando Liberação': this.redaction }}
                        </strong>
                    </p>
                </mat-card-content>
            </mat-card> -->
        </div>
        <mat-card fxLayout="column" class='released released-column'>
            <mat-card-content class='activity-card'>
                <p>
                    <strong>
                        Trilha do desafio Liberada
                    </strong>
                </p>
            </mat-card-content>
        </mat-card>
    </div>

    <!-- <div class="div-steps">
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title>Como você agiria nessa situação</mat-card-title>
            </mat-card-header>
            <mat-icon class="icone"> import_contacts</mat-icon>
            <mat-card-actions>
                <div fxLayout="row" fxLayoutAlign="center" class="textAlignEnd">
                    <a mat-raised-button color="primary" href="https://nau.tamboro.com.br/#/" [disabled]="!activityReleased || (this.studyCase == 'Atividade já realizada')" target="_blank">Fazer</a>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card [class]="this.studyCaseClass">
            <mat-card-content>
                <p><strong>{{ !activityReleased? 'Aguardando Liberação': this.studyCase }}</strong></p>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="div-steps">
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title>Questões</mat-card-title>
            </mat-card-header>
            <mat-icon class="icone">ballot</mat-icon>
            <mat-card-actions>
                <div fxLayout="row" fxLayoutAlign="center" class="textAlignEnd">
                    <a mat-raised-button color="primary" href="https://nau.tamboro.com.br/#/" [disabled]="!activityReleased || (this.multipleChoice == 'Atividade já realizada')" target="_blank">Fazer</a>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card [class]="this.multipleChoiceClass">
            <mat-card-content>
                <p><strong>{{ !activityReleased? 'Aguardando Liberação': this.multipleChoice }}</strong></p>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="div-steps">
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title>Entrevista</mat-card-title>
            </mat-card-header>
            <mat-icon class="icone">videocam</mat-icon>
            <mat-card-actions>
                <div fxLayout="row" fxLayoutAlign="center" class="textAlignEnd">
                    <a mat-raised-button color="primary" href="https://projeto-nau.reservio.com" [disabled]="!interviewReleased" target="_blank">Agendar</a>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card [class]="this.interviewClass">
            <mat-card-content>
                <p><strong>{{ !interviewReleased? 'Aguardando Liberação': this.interview }}</strong></p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="div-steps">
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title>Upload de Documentos</mat-card-title>
            </mat-card-header>
            <div style="width: 232px; height: 190px;">
                <mat-icon class="icone">file_copy</mat-icon>
            </div>
            <mat-card-actions>
                <div fxLayout="row" fxLayoutAlign="center" class="textAlignEnd">
                    <button mat-raised-button color="primary" sytle="margin:auto;" [routerLink]="['/upload-documentos']">Fazer</button>
                </div>
            </mat-card-actions>
        </mat-card>
        <mat-card class="released">
            <mat-card-content>
                <p><strong>Atividade Liberada</strong></p>
            </mat-card-content>
        </mat-card>
    </div> -->
</div>