<div class="login-content" fxLayout="row" fxLayoutAlign="center center">
    <!-- error alert may contain warning alerts too -->
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <mat-card-content style='margin-top: 0 !important'[innerHTML]="resultMessage"></mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button mat-raised-button color="warn" (click)='toogleMessageShow(false)'>OK</button>
            </div>
        </mat-card>
    </div>
    <!-- user alert is f -->
    <app-user-alert
        [warningEmailShow]="warningEmailShow"
        [messageTitle]="messageTitle"
        [messageContent]="messageContent"
        [resultMessage]="resultMessage"
    ></app-user-alert>
    <br>
    <mat-card>
        <mat-card-title>Login</mat-card-title>
        <mat-card-content>

            <mat-form-field appearance="fill">
                <mat-label [ngClass]="{ 'emailForm.valid': 'colorlightgreen' }">Digite seu e-mail : </mat-label>
                <input matInput type="email" [formControl]="emailForm" [(ngModel)]="email" (keyup)="formatEmail(email)" />
                <mat-icon matSuffix *ngIf="emailForm.valid" [ngClass]="{'emailForm.valid': 'colorlightgreen' }">checked</mat-icon>
                <mat-error *ngIf="emailForm.invalid">{{ getEmailErrorMessage() }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Digite a sua senha</mat-label>
                <input matInput input.mat-input-element.my-custom-class [type]="hide ? 'password' : 'text'" [formControl]="pwdForm" [(ngModel)]="password" />
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="pwdForm.invalid">{{ getPwdErrorMessage() }}</mat-error>
            </mat-form-field>
            <br>
            <mat-error *ngIf="error">{{ errorMessage }}</mat-error>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd">
                <button [disabled]="!(emailForm.valid && pwdForm.valid)" mat-raised-button color="primary" (click)='userAuth()'>Entrar</button>
                <mat-progress-bar *ngIf="requestionServer" mode="indeterminate"></mat-progress-bar>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-raised-button (click)='accountCreateuser()'>Criar Conta</button>
                <button mat-raised-button (click)='forgotAccount()'>
                    Esqueci minha senha
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
