import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-registration-completed',
  templateUrl: './registration-completed.component.html',
  styleUrls: ['./registration-completed.component.css']
})
export class RegistrationCompletedComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  messageShow: boolean = false;
  messageTitle: string;
  messageContent: string; 

  ngOnInit(): void {

    this.userService.getSubscriptionStatus(sessionStorage.getItem('authToken'))
    .subscribe(
      result => {
        if(result.is_register_valid == true) {
          return this.router.navigate(['plataforma-candidato']);
        }
      },
      error => {
        this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
        console.log(error);
      }
    )

    window.scrollTo(0, 0);

    if(!sessionStorage.getItem('finalEmailSent')){
      this.userService.sendFinalMail(sessionStorage.getItem('authToken'))
      .subscribe(
        data => {
          sessionStorage.setItem('finalEmailSent', 'true');
        },
        error => {
          console.log(error);
        }
      )
    }
  }

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  finalizeRegistration() {
    this.userService.updateSubscriptionStatus(sessionStorage.getItem('authToken'))
      .subscribe(
        result => {
          let token = sessionStorage.getItem('authToken');
          let idUser = ((new JwtHelperService).decodeToken(token)).id_user;
          sessionStorage.setItem('idUser', idUser);

          this.router.navigate(['plataforma-candidato']);
        },
        error => {
          console.log(error);
          this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
        }
      )
  }


}
