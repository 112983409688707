<div class="form-content" fxLayout="row" fxLayoutAlign="center center">

    <mat-card>
        <mat-card-title>Roteiro de Entrevista</mat-card-title>
        <mat-card-subtitle>Entrevistador : {{ fullNameTutor }}</mat-card-subtitle>
        <mat-card-content>

            <!-- Roteiro de entrevista online -->

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.nome_candidato }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" name="nome-do-candidato" [(ngModel)]="this.interview.nome_candidato" />
            </mat-form-field>
            <br>

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[0] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="interview.respostas[0]" />
            </mat-form-field>
            <br>

            <span class="box-tip">
                <p>Maiores Desafios e Vulnerabilidade</p>
                <strong>DICA: </strong>Avalie na resposta do jovem o que ele conta a respeito de si,
                considerando desafios enfrentados. Em como na construção de sua história de vida foi impactado por
                fatores que o colocaram em vulnerabilidade tanto social, quando de seus aspectos psicológicos.
            </span>
            <br>

            <mat-slider (change)="saveInterview()" color="primary" thumbLabel min="1" max="5" step="1" [(ngModel)]="interview.notas[0]"></mat-slider>
            <br>

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[1] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="interview.respostas[1]" />
            </mat-form-field>
            <br>

            <span class="box-tip">
                <p>Maior Capacidade de Argumentação</p>
                <strong>DICA: </strong>( onde 1 é alta capacidade argumentativa e 5 baixa capacidade )
                capacidade de argumentação e engajamento com a sua própria história
            </span>
            <br>

            <mat-slider (change)="saveInterview()" color="primary" thumbLabel min="1" max="5" step="1" [(ngModel)]="interview.notas[1]"></mat-slider>
            <br>


            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[2] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="interview.respostas[2]" />
            </mat-form-field>
            <br>

            <span class="box-tip">
                <p>Maior Clareza do Objetivo - onde quer chegar</p>
                <strong>DICA: </strong>Avalie se na fala do jovem como estão suas perspectivas em relação a sua própria história de vida.
            </span>
            <br>

            <mat-slider (change)="saveInterview()" color="primary" thumbLabel min="1" max="5" step="1" [(ngModel)]="interview.notas[2]"></mat-slider>
            <br>

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[3] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="interview.respostas[3]" />
            </mat-form-field>
            <br>

            <span class="box-tip">
                <p>Maior reconhecimento das suas potencialidades</p>
                <strong>DICA: </strong>Avalie se o jovem é capaz de reconhecer suas potencilidades</span>
            <br>

            <mat-slider (change)="saveInterview()" color="primary" thumbLabel min="1" max="5" step="1" [(ngModel)]="interview.notas[3]"></mat-slider>
            <br>

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[4] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="interview.respostas[4]" />
            </mat-form-field>
            <br>

            <span class="box-tip">
                <p>Falta de oportunidade de lazer</p>
                <strong>DICA: </strong>Observe se o jovem tem acesso e oportunidades de lazer, considerando a importancia de viver momentos para além das responsabilidades sociais e economicas.</span>
            <br>

            <mat-slider (change)="saveInterview()" color="primary" thumbLabel min="1" max="5" step="1" [(ngModel)]="interview.notas[4]"></mat-slider>
            <br>

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[5] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="interview.respostas[5]" />
            </mat-form-field>
            <br>

            <span class="box-tip">
                <p>Capacidade de Organização</p>
                <strong>DICA: </strong>Avalie a percepção que o jovem tem de sua organização e de pensar essa competência em seu cotidiano. Não limitando a vivências ligadas a sua vida profissional, mas também em sua vida pessoal. </span>
            <br>

            <mat-slider (change)="saveInterview()" color="primary" thumbLabel min="1" max="5" step="1" [(ngModel)]="interview.notas[5]"></mat-slider>
            <br>

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[6] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="interview.respostas[6]" />
            </mat-form-field>
            <br>

            <span class="box-tip">
                <p>Maiores Desafios e Vulnerabilidade</p>
                <strong>DICA: </strong>Avalie na resposta do jovem o que ele conta a respeito de si,
                considerando desafios enfrentados. Em como na construção de sua história de vida foi impactado por
                fatores que o colocaram em vulnerabilidade tanto social, quando de seus aspectos psicológicos.
            </span>
            <br>

            <mat-slider (change)="saveInterview()" color="primary" thumbLabel min="1" max="5" step="1" [(ngModel)]="interview.notas[6]"></mat-slider>
            <br>

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[7] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="interview.respostas[7]" />
            </mat-form-field>
            <br>

            <span class="box-tip">
                <p>Auto-estima mais baixa</p>
                <strong>DICA: </strong>Observe se e como o jovem atribui ao seu valor a aquilo que realiza
            </span>
            <br>

            <mat-slider (change)="saveInterview()" color="primary" thumbLabel min="1" max="5" step="1" [(ngModel)]="interview.notas[7]"></mat-slider>
            <br>

        </mat-card-content>
    </mat-card>

    <br><br>

    <mat-card>
        <mat-card-title>Questionário Psicossocial - Aspectos Psicológicos</mat-card-title>
        <br>
        <mat-card-content>

            <mat-radio-group [(ngModel)]="interview.respostas[8]">
                <mat-label>{{ interview.perguntas[8] }}</mat-label>

                <span class="box-tip"><strong>DICA: </strong>
                    Auto-estima mais baixa
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>

            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[9]">
                <mat-label>{{ interview.perguntas[9] }}</mat-label>
                <span class="box-tip">
                    <strong>DICA: </strong> Nível de pressão - (mais pressionados)
                </span>
                <br>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>



            <mat-radio-group [(ngModel)]="interview.respostas[10]">
                <mat-label>{{ interview.perguntas[10] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong> Potencial de desânimo com a vida
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[11]">
                <mat-label>{{ interview.perguntas[11] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong>Ansiedade ( mais ansiosos )
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[12]">
                <mat-label>{{ interview.perguntas[12] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong>Nível de prejuízo psicossocial para a convivência
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="sim">Sim</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não sei dizer">Não sei dizer</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não">Não</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[13]">
                <mat-label>{{ interview.perguntas[13] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong>Adoecimento na família (mais vulneráveis)
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="sim">Sim</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não sei dizer">Não sei dizer</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não">Não</mat-radio-button>
            </mat-radio-group>

        </mat-card-content>
    </mat-card>

    <br><br>

    <mat-card>
        <mat-card-title>Questionário Psicossocial - Aspectos Sociais</mat-card-title>
        <br>
        <mat-card-content>


            <mat-radio-group [(ngModel)]="interview.respostas[14]">
                <mat-label>{{ interview.perguntas[14] }}</mat-label>

                <span class="box-tip">
                    <p>Tipos de deficiência : física e intelectual</p>
                    <strong>DICA: </strong>Com essa questão podemos compreender a
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="sim">Sim</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não sei dizer">Não sei dizer</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não">Não</mat-radio-button>
            </mat-radio-group>

            <!-- MELHORIA PEDIDA APOS APROVAÇÃO DOS REQUISITOS POR ISSO INDICE 48 -->
            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[48] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="this.interview.respostas[48]" />
            </mat-form-field>
            <br>

            <mat-radio-group [(ngModel)]="interview.respostas[15]">
                <mat-label>{{ interview.perguntas[15] }}</mat-label>

                <span class="box-tip">
                    <p>Mais necessidade de entrar no mercado de trabalho ao mesmo tempo mais dificuldade</p>
                    <strong>DICA: </strong>Avalie que jovens com filhos muitas vezes encontram mais desafios para entrada e permanência no mercado de trabalho, sendo asssim considere a importancia do acolhimento e orientações possibilitados pelo Nau.
                </span>
                <br>



                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="sim">Sim</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não sei dizer">Não sei dizer</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não">Não</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[16]">
                <mat-label>{{ interview.perguntas[16] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong>Engajamento social
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="sim">Sim</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não sei dizer">Não sei dizer</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não">Não</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[17]">
                <mat-label>{{ interview.perguntas[17] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong>Engajamento social / pertencimento
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="sim">Sim</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não sei dizer">Não sei dizer</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="não">Não</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[18]">
                <mat-label>{{ interview.perguntas[18] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong>Engajamento social / pertencimento
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[19]">
                <mat-label>{{ interview.perguntas[19] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong>Vinculos Familiáries Frágeis
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[20]">
                <mat-label>{{ interview.perguntas[20] }}</mat-label>

                <span class="box-tip">
                    <strong>DICA: </strong>Pouco Amparo Familiar
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>


            <mat-radio-group [(ngModel)]="interview.respostas[21]">
                <mat-label>{{ interview.perguntas[21] }}</mat-label>

                <span class="box-tip">
                    <p>Vínculos afetivos com pessoas da mesma idade</p>
                    <strong>DICA: </strong>Avalie como está a rede de apoio desse jovem, se ele sente que há pessoas que podem contribuir para que ele enfrente seus problemas e desafios ou se sente sozinho, sem ter com quem contar quando há necessidade. 
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[22]">
                <mat-label>{{ interview.perguntas[22] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[23]">
                <mat-label>{{ interview.perguntas[23] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong>DICA: </strong>Acesso à alimentação

                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[24]">
                <mat-label>{{ interview.perguntas[24] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong>DICA: </strong>Vulnerabilidade Social

                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="sempre">Sempre</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="frequentemente">Frequentemente</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="às vezes">Às vezes</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="quase nunca">Quase nunca</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-middle" color="primary" value="nunca">Nunca</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[25]">
                <mat-label>{{ interview.perguntas[25] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[26]">
                <mat-label>{{ interview.perguntas[26] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong>DICA: </strong>Itens Prioritários (P)

                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[27]">
                <mat-label>{{ interview.perguntas[27] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[28]">
                <mat-label>{{ interview.perguntas[28] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong>DICA: </strong>1 - fator importante de classificação

                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[29]">
                <mat-label>{{ interview.perguntas[29] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[30]">
                <mat-label>{{ interview.perguntas[30] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[31]">
                <mat-label>{{ interview.perguntas[31] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[32]">
                <mat-label>{{ interview.perguntas[32] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[33]">
                <mat-label>{{ interview.perguntas[33] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[34]">
                <mat-label>{{ interview.perguntas[34] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[35]">
                <mat-label>{{ interview.perguntas[35] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[36]">
                <mat-label>{{ interview.perguntas[36] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[37]">
                <mat-label>{{ interview.perguntas[37] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[38]">
                <mat-label>{{ interview.perguntas[38] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[39]">
                <mat-label>{{ interview.perguntas[39] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[40]">
                <mat-label>{{ interview.perguntas[40] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>

                <br>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[41]">
                <mat-label>{{ interview.perguntas[41] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[42]">
                <mat-label>{{ interview.perguntas[42] }}</mat-label>

                <span class="box-tip">
                    <p></p>
                    <strong></strong>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="0">0</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="3">3</mat-radio-button>
            </mat-radio-group>

            <span class="box-tip">
                <p></p>
                <strong>DICA: </strong>3 pessoas ou mais por cômodo
            </span>
            <br>

            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[43] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" name="nome-do-candidato" [(ngModel)]="this.interview.respostas[43]" />
            </mat-form-field>
            <br>



        </mat-card-content>
    </mat-card>

    <br><br>

    <mat-card>
        <mat-card-title>Informações de residentes</mat-card-title>
        <mat-card-content>

            <div *ngFor="let resident of residents; let i = index">
                <h3>
                    Residente {{ i + 1 }}
                </h3>
                <mat-form-field appearance="fill">
                    <mat-label>Nome completo</mat-label>
                    <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="resident.nome" />
                </mat-form-field>
                <br>

                <mat-form-field appearance="fill">
                    <mat-label>Idade</mat-label>
                    <input (blur)="saveInterview()" matInput type="number" [(ngModel)]="resident.idade" />
                </mat-form-field>
                <br>

                <mat-form-field appearance="fill">
                    <mat-label>Parentesco</mat-label>
                    <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="resident.parentesco" />
                </mat-form-field>
                <br>

                <mat-radio-group [(ngModel)]="resident.estado_civil">
                    <mat-label>ESTADO CIVIL</mat-label>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="1">Solteir@</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="2">Casad@</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="3">Separad@</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="4">Divorciad@</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="5">Viuv@</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="6">União estável</mat-radio-button>
                </mat-radio-group>

                <mat-radio-group aria-label="POSSUI DEFICIÊNCIA?" [(ngModel)]="resident.deficiencia">
                    <mat-label>POSSUI DEFICIÊNCIA?</mat-label>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="1">Sim</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="2">Não</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="3">Não sei</mat-radio-button>
                </mat-radio-group>

                <mat-radio-group aria-label="ESCOLARIDADE" [(ngModel)]="resident.escolaridade">
                    <mat-label>ESCOLARIDADE</mat-label>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="1">Ensino fundamental</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="2">Ensino básico</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="3">Ensino médio</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="4">Ensino superior incompleto</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="5">Ensino superior completo</mat-radio-button>
                </mat-radio-group>

                <mat-form-field appearance="fill">
                    <mat-label>Ocupação</mat-label>
                    <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="resident.ocupacao" />
                </mat-form-field>
                <br>

                <mat-radio-group aria-label="RENDA" [(ngModel)]="resident.renda">
                    <mat-label>RENDA</mat-label>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="1">Até R$1.000,00</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="2">De R$1.000,00 à R$2.000,00</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="3">De R$2.000,00 à R$3.000,00</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="4">De R$3.000,00 à R$4.000,00</mat-radio-button>
                    <mat-radio-button (change)="saveInterview()" color="primary" value="5">Acima de R$4.000,00</mat-radio-button>
                </mat-radio-group>
            </div>
            <button mat-stroked-button color="primary" (click)="addResidents()">Adicionar residentes</button><br>
        </mat-card-content>
    </mat-card>

    <br><br>

    <mat-card>
        <mat-card-title>Avaliação Final</mat-card-title>
        <mat-card-content>

            <mat-radio-group [(ngModel)]="interview.respostas[44]">
                <mat-label>{{ interview.perguntas[44] }}</mat-label>

                <span class="box-tip">
                    <p>Pensando nos aspectos sociais, o quanto você avalia que o jovem deve ser recebido pelo NAU?</p>
                    <strong>DICA: </strong>Ao final da conversa com o jovem, entendemos que foi possível conhecer e pensar em seus aspectos sociais e psicológicas em diálogo, de forma integral e contextualizada. Acreditamos que foi possível uma maior paroximação e apreensão da realidade do jovem, compreendendo seus limites e possibildades. Assim, um melhor entendimento de seu projeto e condições de vida, rede de apoio e relações. Sendo assim, dê uma nota de 0 a 5 para a permitinência do treinamento para jovem em relação aos seus aspectos sociais e psicológicos.
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="3">3</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="4">4</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="5">5</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[45]">
                <mat-label>{{ interview.perguntas[45] }}</mat-label>

                <span class="box-tip">
                    <p>Pensando nos aspectos psicológicosicos, o quanto você avalia que o jovem deve ser recebido pelo NAU?</p>
                    <strong>DICA: </strong>Ao final da conversa com o jovem, entendemos que foi possível conhecer e pensar em seus aspectos sociais e psicológicas em diálogo, de forma integral e contextualizada. Acreditamos que foi possível uma maior paroximação e apreensão da realidade do jovem, compreendendo seus limites e possibildades. Assim, um melhor entendimento de seu projeto e condições de vida, rede de apoio e relações. Sendo assim, dê uma nota de 0 a 5 para a permitinência do treinamento para jovem em relação aos seus aspectos sociais e psicológicos.
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="3">3</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="4">4</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="5">5</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group [(ngModel)]="interview.respostas[46]">
                <mat-label>{{ interview.perguntas[46] }}</mat-label>

                <span class="box-tip">
                    <p>Critérios de correção da redação</p>
                    <p><strong>Foco no trabalho: </strong>Ao final da conversa com o jovem, entendemos que foi possível conhecer e pensar em seus aspectos sociais e psicológicas em diálogo, de forma integral e contextualizada. Acreditamos que foi possível uma maior paroximação e apreensão da realidade do jovem, compreendendo seus limites e possibildades. Assim, um melhor entendimento de seu projeto e condições de vida, rede de apoio e relações. Sendo assim, dê uma nota de 0 a 5 para a permitinência do treinamento para jovem em relação aos seus aspectos sociais e psicológicos.</p>
                    <p><strong>Persistência: </strong>Não desistir das coisas que começa - quanto mais persistência mais o perfil do NAU</p>
                    <p><strong>Resolução de Problemas: </strong>Como ele lidou e resolveu os problemas que apareceram ao longo do caminho - quanto mais capacidade de resolver os problemas mais o perfil do NAU</p>
                    <p><strong>Organização da história: </strong>Como ele consegue contar a história da sua vida até chegar ao momento atual - capacidade do avaliador entender o que está sendo relatado - quanto mais entender a história da vida mais o perfil do NAU.</p>
                </span>
                <br>

                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="1">1</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="2">2</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="3">3</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="4">4</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-big" color="primary" value="5">5</mat-radio-button>
            </mat-radio-group>


            <mat-form-field appearance="fill">
                <mat-label>{{ interview.perguntas[47] }}</mat-label>
                <input (blur)="saveInterview()" matInput type="text" [(ngModel)]="this.interview.respostas[47]" />
            </mat-form-field>
            <br>

            <mat-radio-group [(ngModel)]="interview.respostas[49]">
                <mat-label>{{ interview.perguntas[49] }}</mat-label>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="manhã">Manhã</mat-radio-button>
                <mat-radio-button (change)="saveInterview()" class="margin-button-biggest" color="primary" value="tarde">Tarde</mat-radio-button>
            </mat-radio-group>
            <br>
            <br>

            <button mat-button [routerLink]="['/plataforma-tutor']">Voltar</button>
            <button class="btn-save" mat-stroked-button color="primary" (click)="saveInterview(true)">
                <span *ngIf="!loading">
                    Salvar
                </span>
                <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
            </button>
            <button *ngIf="false" mat-stroked-button color="primary" (click)="showObject()">Show</button>
        </mat-card-content>
    </mat-card>
    <br><br><br>
</div>