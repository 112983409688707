<mat-list>
    <mat-list-item *ngFor="let warning of warnings">
        <mat-icon mat-list-icon>{{ warning.icon }}</mat-icon>
        <div mat-line>{{ warning.text }}</div>
    </mat-list-item>

    <div mat-subheader>Você deve deixar separados os seguintes documentos :</div>
    <mat-list-item *ngFor="let document of documents">
        <mat-icon mat-list-icon>{{ document.icon }}</mat-icon>
        <div mat-line>{{ document.text }}</div>
    </mat-list-item>
</mat-list>