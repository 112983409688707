<div class="sidebar">
    <div class="profile">
        <div class="avatar">
            <div class="avatar-img">
            </div>
        </div>
        <div class="email">
            <h1>heyfromjonathan@gmail.com</h1>
        </div>
    </div>
    <div class="list-menu">
        <div class="item" *ngFor="let item of myRoutes" [routerLink]="item.destination">
            <div class="icon">
                <mat-icon>{{item.icon}}</mat-icon>
            </div>
            <div class="name-item">
                <p>
                    {{item.name}}
                </p>
            </div>
        </div>
    </div>
</div>