import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  constructor(private http: HttpClient) { }

  public getActivity(token: string, guid: string, activity: string): Observable<any> {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}user-activity/${activity}/${guid}`, header);

  }

  public getActivitiesStatus(token: string, id_user:number) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}activities-status/${id_user}`, header);
  }

  public saveCorrection(token: string, id_user: number, fields: Object) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.post<any>(`${environment.URL_SERVER}correction/${id_user}`, fields, header);

  }

  public getCorrection(token: string, id_user: number) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}correction/${id_user}`, header);

  }
}
