<div class="registration-completed-content" fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button mat-raised-button color="warn" (click)="toogleMessageShow(false);">OK</button>
            </div>
        </mat-card>
    </div>

    <mat-card>
        <mat-card-title></mat-card-title>
        <mat-card-content align=start>
            <!--
            <div fxLayout="row" fxLayoutAlign="space-around center" aling="" center>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/BeHXDaKnpbQ?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            -->
            <h1 style='text-align: center;'>Parabéns, jovem!</h1>
            <br>
            <p>Sua inscrição está confirmada e estamos felizes que 
                irá participar de um processo seletivo em busca de 
                autoconhecimento visando o mercado de trabalho!</p>
            <br>
            <p>
                Agora você tem mais duas etapas pela frente:
            </p>
            <ol type="1">
                <mat-list>
                    <li>
                        <mat-list-item class='list-item'>
                            <p class='scratched-text'>Cadastro</p>
                            <mat-icon>check_circle_outline</mat-icon>
                        </mat-list-item>
                    </li>
                    <li>
                        <mat-list-item class='list-item'>
                            <p class='scratched-text'>Confirmar inscrição</p>
                            <mat-icon>check_circle_outline</mat-icon>
                        </mat-list-item>
                    </li>
                    <b>
                        <li>
                            <mat-list-item>
                                Trilha do Desafio
                            </mat-list-item>
                        </li>
                    </b>
                    <li>
                        <mat-list-item>
                            Entrevista
                        </mat-list-item>
                    </li>
                </mat-list>
            </ol>
            <p>
                A <b>Trilha Desafio</b> é super rápida! Vamos
                te fazer umas perguntinhas sobre sua história 
                e sobre outros assuntos. <b>Não leva nem 15 minutos!</b>
            </p>
            <!-- <p>Você ainda precisa realizar as próximas etapas do 
                processo seletivo para continuar, e elas já estão 
                disponíveis na plataforma!</p>
            <br>
            <p>Utilize seu login e senha para acessar a plataforma 
                e concluir as etapas.</p> -->
        </mat-card-content>
        <mat-card-actions align=center>
            <button
                mat-raised-button
                color="primary"
                (click)="finalizeRegistration()">
                    Bora lá!
            </button>
        </mat-card-actions>
    </mat-card>
    <br>
    <br>
    <br>
</div>