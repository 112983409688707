import { Output, ViewChild, EventEmitter, Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from 'src/app/Services/user.service';
import { UserActivityService } from 'src/app/Services/user-activity.service'
import { User } from 'src/app/Model/user.class';
import { MatSelectionList} from '@angular/material/list';
import { MatOption } from '@angular/material/core';
import { DialogService } from './dialog.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class FormComponent implements OnInit {

  @ViewChild('listOptions') criteriosOptions: MatSelectionList;
  @Input() user: User = new User();

  userData = {
    phone: "",
    idade: 0,
  }

  criterios: any[] = [
   {text: " As pessoas que moram comigo recebem, no total, até 4 salários mínimos no mês (R$4.848,00)",
    isSelect: false},
   {text: " Tenho entre 16 a 24 anos",
   isSelect: false},
   {text: " Já conclui ou estou cursando o 3º ano ensino médio",
   isSelect: false},
   {text: " Estou desempregado", isSelect: false},
   {text: " Declaro que as informações acima são verdadeiras", isSelect: false}
  ]

  error: boolean = false

  /* modal variables */
  messageShow: boolean = false;
  errorMessage: string = "";
  messageTitle: string;
  messageContent: string;

  modalShow: boolean = false;
  modalTitle: string;
  modalContent: string;

  accountUpdated: boolean = false;

  requestionServer: boolean = false;

  form: FormGroup
  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public activityService: UserActivityService,
    private formBuilder: FormBuilder,
    public dialogService: DialogService,
    private router: Router  ) {
      this.form = this.formBuilder.group({
        phone: [this.userData.phone, [Validators.required, Validators.minLength(13)]],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        birthDay: ["", [Validators.required, this.datePickerValidator()]]
      })
      
    }
    
  ngOnInit(): void {
    const jwtToken = sessionStorage.getItem('authToken');
    const idUser = Number(sessionStorage.getItem('idUser'));
    const jwt = new JwtHelperService();
    const guid = (jwt.decodeToken(jwtToken)).guid;

    this.userService.getSubscriptionStatus(sessionStorage.getItem('authToken'))
    .subscribe(
      result => {
        if(result.is_register_valid == true) {
          return this.router.navigate(['plataforma-candidato']);
        }
      },
      error => {
        this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
        console.log(error);
      }
    )

    if ( !sessionStorage.getItem('authToken') ) {
      this.router.navigate(['/login']);
    }

    if(!localStorage.getItem('LGPD')) {
      this.modal('Lei geral de proteção de dados', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fermentum erat ut mi viverra fermentum. Maecenas malesuada tempus urna. Proin tincidunt, nibh vel feugiat ultrices, leo lectus scelerisque orci, a ultricies lorem odio id purus. Sed tincidunt egestas dolor, id laoreet elit ultrices quis. Pellentesque non neque a lacus sollicitudin venenatis. Maecenas semper dictum lacinia. Morbi malesuada dolor ut elit rhoncus vulputate. Proin pretium pellentesque lorem eu commodo. In laoreet interdum purus, id sagittis ipsum volutpat ac. Proin volutpat eget ipsum vitae pulvinar. Cras ac dolor neque. Etiam tincidunt neque varius, scelerisque ligula sit amet, blandit dui. Phasellus vitae quam dui. Etiam nec dapibus nibh. Phasellus dui nulla, blandit sed nisl sed, sollicitudin venenatis leo. Mauris vel consequat ligula.');
    }


    this.userService.getUserData(jwtToken, guid)
      .subscribe(result => {
        this.userData.phone = result.data.phone_number;
        if(result.data.phone_number != '' && result.data.phone_number != null){
          this.formatPhone(this.userData.phone);
        }
        this.user.firstName = result.data.first_name;
        this.user.lastName = result.data.last_name;
        // let tmpBirthDate = new Date(result.data.birth_date);
        // this.userBirthDay = (tmpBirthDate.valueOf() == 785728800000 ? '' : result.data.birth_date);
      }, failure => {
        if(failure.error.code == 2) {
          this.messageBox( "Ops!", failure.error.message );
          this.accountUpdated = true;
        } else {
          console.log(failure);
          this.messageBox( "Ops!",'Houve um problema na conexão com o Servidor : ' + failure.error.message );
        }
      })
  }

  acceptLGDP(): void {
    this.modalShow = false;
    localStorage.setItem('LGPD', 'true');
  }

  declineLGDP(): void{
    this.modalShow = false;
    this.router.navigate(['/login'])
  }

  toggleModalShow(show: boolean): void {
    this.modalShow = show;
  }

  modal(title: string, content: string): void {
    this.modalTitle = title;
    this.modalContent = content;
    this.toggleModalShow(true);
  }

  getAge(dateValue: any): number {
    const nasc = new Date(dateValue);
    const hoje = new Date();
    const diff = Math.abs(hoje.getTime() - nasc.getTime());
    const age = Math.floor(diff/ (1000*60*60*24*365.25) );
    return this.userData.idade = age;
  }

  datePickerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden;
      if (control.value) {
        this.getAge(control.value)
        if ((this.userData.idade < 16 || this.userData.idade > 24)) {
          forbidden = true;
        }
        else {
          forbidden = false;
        }
      }
      return forbidden ? { 'invalidAge': true } : null;
    };
  }

  /* export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = nameRe.test(control.value);
      return forbidden ? {forbiddenName: {value: control.value}} : null;
    };
  }
 */

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  specialMessage(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  /* isCpfValid(cpf): boolean {
    let result = false;
    let newCPF = cpf;
    let digitoVerificador1: number = 0;
    let digitoVerificador2: number = 0;

    newCPF = cpf.replace(/[^0-9]/g,"");
    let numbersOfCpf = newCPF.split('');

    digitoVerificador1 = (
      (
        (
          ( numbersOfCpf[0]  * 10 ) +
          ( numbersOfCpf[1]  *  9 ) +
          ( numbersOfCpf[2]  *  8 ) +
          ( numbersOfCpf[3]  *  7 ) +
          ( numbersOfCpf[4]  *  6 ) +
          ( numbersOfCpf[5]  *  5 ) +
          ( numbersOfCpf[6]  *  4 ) +
          ( numbersOfCpf[7]  *  3 ) +
          ( numbersOfCpf[8]  *  2 )
        ) * 10
      ) % 11
    )

    digitoVerificador2 = (
      (
        (
          ( numbersOfCpf[0]  * 11 ) +
          ( numbersOfCpf[1]  * 10 ) +
          ( numbersOfCpf[2]  *  9 ) +
          ( numbersOfCpf[3]  *  8 ) +
          ( numbersOfCpf[4]  *  7 ) +
          ( numbersOfCpf[5]  *  6 ) +
          ( numbersOfCpf[6]  *  5 ) +
          ( numbersOfCpf[7]  *  4 ) +
          ( numbersOfCpf[8]  *  3 ) +
          ( numbersOfCpf[9]  *  2 )
        ) * 10
      ) % 11
    )

    if ( digitoVerificador1 === 10 ) digitoVerificador1 = 0;
    if ( digitoVerificador2 === 10 ) digitoVerificador2 = 0;

    if (
      numbersOfCpf[0] === numbersOfCpf[1] &&
      numbersOfCpf[1] === numbersOfCpf[2] &&
      numbersOfCpf[2] === numbersOfCpf[3] &&
      numbersOfCpf[3] === numbersOfCpf[4] &&
      numbersOfCpf[4] === numbersOfCpf[5] &&
      numbersOfCpf[5] === numbersOfCpf[6] &&
      numbersOfCpf[6] === numbersOfCpf[7] &&
      numbersOfCpf[7] === numbersOfCpf[8] &&
      numbersOfCpf[8] === numbersOfCpf[9] ) {
        this.messageBox("Ops!", 'Este CPF não é válido!');
        result = false;
    }
    else if ( digitoVerificador1 == numbersOfCpf[9] && digitoVerificador2 == numbersOfCpf[10] ) {
        result = true;
    }
    else
    {
      this.messageBox("Ops!",'Este CPF não é válido!' );
    }
    return result;
  } */


  onFormData() {
    this.dialogService.resetDialog();
    if ((this.checkCriterios() && this.checkInputs()) && this.form.status == "VALID" ) {
      let tempPhone: string = this.userData.phone.replace(/[^\d]/g, '');
      let formResult = {
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        phone_number: tempPhone,
        birth_date: this.form.value.birthDay,
      }
      this.dialogService.changeDialog(formResult)
    }
    this.router.navigate(['/fique-conosco']);
  }

  criterioClick(criterioId: any){
    if (criterioId === 4) {
      this.criterios[4].isSelect = !this.criterios[4].isSelect;
      this.toggleAllCriterios(this.criterios[4].isSelect)
    } else {
      this.criterios[criterioId].isSelect = !!this.criterios[criterioId].isSelect;
    }
  }

  toggleAllCriterios(selected: boolean) {
    if (selected == false) {
      this.criteriosOptions.deselectAll()
    } else {
      this.criteriosOptions.selectAll()
    }
  }

  checkCriterios(): boolean{
    if ( this.criterios[0].isSelected === false )
      return false;
    else if ( this.criterios[1].isSelected === false )
      return false;
    else if ( this.criterios[2].isSelected === false )
      return false;
    else if ( this.criterios[3].isSelected === false )
      return false;
    else if ( this.criterios[4].isSelected === false )
      return false;
    else
      return true;
  }

  checkInputs(): boolean{
    if ( (this.userData.phone === "") || ( this.userData.phone.length < 13)) {
      return false;
    }
    else if ( this.form.value.birthDay.toString() === "") {
      return false;
    }
    else if ( this.userData.idade > 24 || this.userData.idade  < 16 ) {
      return false;
    }
    else {
      return true;
    }
  }

  formatPhone(phone){
    let newPhone = phone;
    newPhone = phone.replace(/[^0-9]/g,"");
    if( newPhone.length < 2 )
      this.userData.phone = "(" + newPhone;
    else if ( newPhone.length < 3 )
      this.userData.phone = "(" + newPhone + ")";
    else if ( newPhone.substring(2,3) === "9") {
      if ( newPhone.length < 8 )
        this.userData.phone = 
        "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,7);
      else
        this.userData.phone = 
        "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,7) +
        "-" + newPhone.substring(7,11);
    }
    else{
      if ( newPhone.length < 7 )
        this.userData.phone =
        "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,6);
      else
        this.userData.phone =
        "(" + newPhone.substring(0,2) + ")" + newPhone.substring(2,6)
        + "-" + newPhone.substring(6,10);
    }
  }

  getFormErrors(input: string): boolean {
    if (this.form.value.input == undefined){
      return true
    }
    else if (this.form.value.input === "") {
      return true;
    }
  }

  Redirect() {
    this.router.navigate(['/inscricao-completa']);
  }

  sendForm() {
    /* let tempCPF: string = this.userCPF.replace(/[^0-9]/g,"") */
    let tempPhone: string = this.userData.phone.replace(/[^\d]/g, '');

    let requestBody = {
      first_name: this.user.firstName,
      last_name: this.user.lastName,
      phone_number: tempPhone,
      birth_date: this.form.value.birthDay,
    };
    
    this.requestionServer = true;
    this.userService.updateUser(requestBody, sessionStorage.getItem("authToken"))
    .subscribe(result => {
      if(result.code == 1) {
        this.requestionServer = false;
        this.specialMessage("Sucesso!", result.message);
        this.accountUpdated = true;
        
      }
    }, failure => {
      if (failure.status == 0) {
        this.messageBox("Ops!", "Erro na comunicação com o servidor   : " + JSON.stringify(failure.statusText));
      } else {
          this.messageBox("Ops!" ,"Verifique se um dos campos não foi preenchido ou se você não atente a um dos requisitos da seleção")
        }
      this.requestionServer = false;
    });
  }

}
