import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User }  from '../Model/user.class';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService {

  constructor (private http: HttpClient ) { }

  public loginUser(user: User): Observable<any> {
    return this.http.post<any>(`${environment.URL_SERVER}auth`, user);
  }

  public createUser(user: User): Observable<any> {
    return this.http.post<any>(`${environment.URL_SERVER}register`, user);
  }

  public sendValidationMail(requestBody: object): Observable<any> {
    return this.http.post<any>(`${environment.URL_SERVER}validate-mail`, requestBody);
  }

  public createParticularUser(user: Object) : Observable<any> {
    return this.http.post<any>(`${environment.URL_SERVER}register-admin`, user);
  }

  public getUserData(token: string, guid: string): Observable<any> {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}user/${guid}`, header);
  }

  public getAllUsers(token: string, page: number, sort: string, find: string = '', alphaBethicOrder: string = ''): Observable<any> {
    
    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    if(find != '') {
      return this.http.get<any>(`${environment.URL_SERVER}users?p=${page}&s=${sort}&find=${find}`, header);
    }

    if(alphaBethicOrder != '') {
      return this.http.get<any>(`${environment.URL_SERVER}users?p=${page}&s=${sort}&order=${alphaBethicOrder}`, header);
    }

    return this.http.get<any>(`${environment.URL_SERVER}users?p=${page}&s=${sort}`, header);
  }

  public updateUser(requestBody: object, token: string) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.patch<any>(`${environment.URL_SERVER}users`, requestBody, header);
  }

  public updateSubscriptionStatus(token: string): Observable<any> {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    let body = {};

    return this.http.put<any>(`${environment.URL_SERVER}user-subscription-status`, body, header);

  }

  public getSubscriptionStatus(token: string): Observable<any> {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}user-subscription-status`, header);

  }

  public verifyMailToken(token: string): Observable<any> {

    return this.http.get<any>(`${environment.URL_SERVER}verify-email?tkn=${token}`);

  }

  public sendFinalMail(token): Observable<any> {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}subscription-finalize`, header);
  }

  public uploadFiles(token: string, fileType: string, id_user: number, file: File) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    let reqBody = new FormData;
    reqBody.append('file', file, file.name);

    return this.http.post<any>(`${environment.URL_SERVER}upload/${fileType}/${id_user}`, reqBody, header);
  }

  public getUploadedFiles(token: string , guid: string) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}upload/${guid}`, header);
  }

  public saveDocApproval(token: string, id_user: number, reqBody: object) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.post<any>(`${environment.URL_SERVER}document-approval/${id_user}`, reqBody, header);
  }

  public getApprovedDocs(token: string, id_user:number) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}document-approval/${id_user}`, header);
  }

  public saveInterview(token: string, guid: string, reqBody: object) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.post<any>(`${environment.URL_SERVER}interview/${guid}`, reqBody, header);
  }

  public getInterview(token: string, guid: string) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.get<any>(`${environment.URL_SERVER}interview/${guid}`, header);
  }

  public calculateUsersScores(token: string) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.put<any>(`${environment.URL_SERVER}score`, '', header);

  }

  public registerUserGiveUp(reqBody: object, token: string, guid: string) {

    let header = {
      headers:new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    }

    return this.http.post<any>(`${environment.URL_SERVER}give-up/${guid}`, reqBody, header);
  }

}
