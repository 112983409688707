<div class="container title-wrapper">
    <mat-icon id="user-icon">person_pin</mat-icon>
    <h3><strong>Bem vindo, {{ userName }}</strong></h3>
    <a title="Sair" (click)="logOut()" mat-icon-button color="primary" class="logOut">
        <mat-icon>exit_to_app</mat-icon>
    </a>
</div>

<div class="container">
    <h3 class="textAlignCenter">
        <button mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button> Lista de candidatos
    </h3>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <table class="mat-elevation-z4 table-striped">
        <mat-form-field class="header-filter">
            <mat-label>Filtrar por</mat-label>
            <mat-select [(ngModel)]="filter">
                <mat-option *ngFor="let option of filterOptions" [value]="option.value" (blur)="sortTable(option.value)">
                    {{ option.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="header-filter order-filter">
            <mat-label>Ordenar usuários</mat-label>
            <mat-select [(ngModel)]="order">
                <mat-option *ngFor="let option of orderOptions" [value]="option.value" (blur)="alphaBethicSort(option.value)">
                    {{ option.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="header-filter search-filter">
            <mat-label>Pesquisar candidato</mat-label>
            <input (keyup.enter)="searchCandidate(this.search)" matInput type="text" [(ngModel)]="search">
            <mat-icon class="search-icon">
                search
            </mat-icon>
        </mat-form-field>
        <thead>
            <th>
                Usuários
            </th>
            <th *ngIf="this.userProfile != 'PSICOLOGO'">
              Redações
            </th>
            <th *ngIf="this.userProfile != 'PSICOLOGO'">
              Renda familiar
            </th>
            <th *ngIf="this.userProfile != 'PSICOLOGO'">
              Provas de múltipla escolha
            </th>
            <th>
                Entrevista
            </th>
            <th *ngIf="this.userProfile != 'PSICOLOGO'">
                Documentos
            </th>
            <th>
                Pts.
            </th>
            <th>
                Classificação
            </th>
            <th>
                Status
            </th>
        </thead>
        <tr class="textAlignCenter" *ngFor="let user of userList">
            <td>
                <button mat-button [routerLink]="['/plataforma-tutor/info-usuario/', user.id_user, user.guid]">
                    {{
                        user.first_name + " " + user.last_name
                    }}
                </button>
            </td>
            <td *ngIf="this.userProfile != 'PSICOLOGO'">
                <button mat-button [class]="user.redaction.class" [disabled]="user.redaction.disabled" (click)="accessUserPage('corrigir-redacao', user)">
                    {{ user.redaction.label }}
                </button>
            </td>
            <td *ngIf="this.userProfile != 'PSICOLOGO'">
              <button mat-button [class]="user.family_income.class" [disabled]="user.family_income.disabled">
                  {{ user.family_income.label }}
              </button>
            </td>
            <td *ngIf="this.userProfile != 'PSICOLOGO'">
              <button mat-button [class]="user.multiple_choice.class" [disabled]="user.multiple_choice.disabled" (click)="accessUserPage('multipla-escolha', user)">
                  {{ user.multiple_choice.label }}
              </button>
            </td>
            <td>
                <button mat-button [class]="user.interview_status.class" (click)="accessUserPage('entrevista', user)">
                    {{ user.interview_status.label }}
                </button>
            </td>
            <td *ngIf="this.userProfile != 'PSICOLOGO'">
                <button mat-button [class]="user.documents.class" [disabled]="user.documents.disabled" (click)="accessUserPage('documentos', user)">
                    {{ user.documents.label }}
                </button>
            </td>
            <td>
                <button [disabled]="user.final_score === '-'" mat-button>
                    {{ user.final_score }}
                </button>
            </td>
            <td>
                <button [disabled]="user.classification === '-' || filter !== 'final_score'" mat-button>
                    {{ filter === 'final_score' ? user.classification : '-'}}
                </button>
            </td>
            <td>
                <button mat-button [class]="user.status.class">
                    {{ user.status.label }}
                </button>
            </td>
        </tr>
    </table>
    <mat-paginator [pageIndex]="pageIndex" [length]="itemLength" [pageSize]="10" (page)="nextPage($event)" showFirstLastButtons>
    </mat-paginator>
</div>
