import { Component, OnInit } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserService} from 'src/app/Services/user.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-give-up',
  templateUrl: './give-up.component.html',
  styleUrls: ['./give-up.component.css']
})
export class GiveUpComponent implements OnInit {

  private jwtToken = sessionStorage.getItem('authToken');
  private candidateGuid: string;

  giveUp = {
    tutorGuid: '',
    reason: '',
    detail: ''
  }

  loading = false;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    const jwtHelper = new JwtHelperService;
    const jwtData = (jwtHelper.decodeToken(this.jwtToken))

    this.giveUp.tutorGuid = jwtData.guid;
    this.candidateGuid = this.route.snapshot.paramMap.get('guid');
    console.log(this.candidateGuid);

  }

  registerGiveUp(): void {

    this.loading = true;
    this.userService.registerUserGiveUp(this.giveUp, this.jwtToken, this.candidateGuid)
      .subscribe(
        _result => {
          this.snackBar.open('O registro foi salvo!', 'Sucesso!', {
            duration: 1500
          });
          this.loading = false;
        },
        error => {
          console.log(error);
        }
      )
  }

}
