import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Email } from '../Model/e-mail.class';

@Injectable()
export class PasswordRecoveryService {

  constructor (private http: HttpClient ) { }

  public recovery(requestBody: object): Observable<any> {
    return this.http.post<any>(`${environment.URL_SERVER}recover-password`, requestBody);
  }

  public validatePassToken(token: string): Observable<any> {
    return this.http.get(`${environment.URL_SERVER}recover-token?tkn=${token}`);
  }

  public newPassword(requestBody: object): Observable<any> {
    return this.http.post(`${environment.URL_SERVER}new-password`, requestBody);
  }

}
