<div class="register-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <mat-card-content style="margin-top: 0;" [innerHTML]="resultMessage"></mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button *ngIf="!accountCreated" mat-raised-button color="warn" (click)="toogleMessageShow(false);">OK</button>
                <button *ngIf="accountCreated" mat-raised-button color="warn" [routerLink]="['/login']">OK</button>
            </div>
        </mat-card>
    </div>
    <br>
    <div class="bg-darken" *ngIf="modalShow">
        <mat-card id="modal-LGPD">
            <mat-card-title> {{ modalTitle }} </mat-card-title>
            <mat-card-content>
                <div class='container' style='width: 100%;height: 50vh'>
                    <iframe class='normal-pdf-screen'
                        src="https://tamboro.blob.core.windows.net/tamboro-nau-prod/terms/2020_11_13_TermosUsoTamboroLGPD-NAU.pdf"
                        type="application/pdf"
                        width="500" height="400">
                        <a href="https://tamboro.blob.core.windows.net/tamboro-nau-prod/terms/2020_11_13_TermosUsoTamboroLGPD-NAU.pdf"></a> 
                    </iframe>
                    <div class='responsive-pdf-screen'>
                        <pdf-viewer
                            [src]="'https://tamboro.blob.core.windows.net/tamboro-nau-prod/terms/2020_11_13_TermosUsoTamboroLGPD-NAU.pdf'"
                            [render-text]="true"
                            [zoom]="2.1"
                            [original-size]="false"
                            style="width: 100%; height: 100%"
                            
                        ></pdf-viewer>
                    </div>
                </div>
            </mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button mat-stroked-button (click)="declineLGDP()">Recusar</button>
                <button mat-raised-button color="primary" (click)="acceptLGDP()">Aceitar</button>
            </div>
        </mat-card>
    </div>
    <mat-card>
        <mat-card-title>Primeira Etapa</mat-card-title>
        <mat-card-subtitle>Crie sua conta para fazer sua inscrição</mat-card-subtitle>
        <mat-card-content>
            <form novalidade (ngSubmit)="userRegister()" [formGroup]="cadastroForm">
            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Nome</mat-label>
                <input matInput
                    type="text"
                    [(ngModel)]="user.firstName"
                    formControlName="fcFirstName"/>
                <mat-error *ngIf="getFormErrors('fcFirstName')">
                    Digite seu primeiro nome.
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Sobrenome</mat-label>
                <input matInput
                    type="text"
                    [(ngModel)]="user.lastName"
                    formControlName="fcLastName"/>
                <mat-error *ngIf="getFormErrors('fcLastName')">
                    Digite seu sobrenome.
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>E-mail</mat-label>
                <input
                    matInput type="email"
                    [(ngModel)]="user.email"
                    formControlName="fcEmail"
                />
                <mat-icon matSuffix *ngIf="cadastroForm.get('fcEmail')?.erros">checked</mat-icon>
                <mat-hint *ngIf='user.email == null'>
                    Exemplo: contato@nau.com.br
                </mat-hint>
                <mat-error
                    *ngIf='cadastroForm.value.fcEmail == undefined ||
                    cadastroForm.value.fcEmail == "" '>
                    Você precisa digitar um email
                </mat-error>
                <mat-error *ngIf="cadastroForm.controls.fcEmail.hasError('email')">
                    Você não digitou um e-mail válido!
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Confirme o E-mail</mat-label>
                <input
                    onpaste="return false"
                    matInput type="email"
                    [(ngModel)]="user.checkEmail"
                    formControlName="fcCheckEmail"
                />
                <mat-icon matSuffix *ngIf="cadastroForm.get('fcCheckEmail')?.erros">checked</mat-icon>
                <mat-error *ngIf='cadastroForm.value.fcCheckEmail == undefined ||
                    cadastroForm.value.fcCheckEmail == "" '>
                    Digite o mesmo email digitado acima
                </mat-error>
                <mat-error
                    *ngIf="cadastroForm.get('fcCheckEmail').hasError('invalidEmails') 
                    && cadastroForm.value.fcCheckEmail !== '' "
                >
                    Os emails não conferem!
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label >WhatsApp com DDD</mat-label>
                <input
                    matInput
                    type="text"
                    [(ngModel)]="user.whatsApp"
                    (keyup)="formatPhone(user.whatsApp)"
                    formControlName="fcWhatsApp"/>
                <mat-icon matSuffix *ngIf="cadastroForm.get('fcWhatsApp')?.erros">
                    checked
                </mat-icon>
                <mat-error *ngIf="cadastroForm.value.fcWhatsApp == undefined ">
                    Você precisa digitar um número de WhatsApp
                </mat-error>
                <mat-error *ngIf="cadastroForm.controls.fcWhatsApp.hasError('minlength')">
                    O número de WhatsApp o (DDD) + 8 a 9 digitos
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Senha</mat-label>
                <input matInput input.mat-input-element.my-custom-class
                    [type]="hide ? 'password' : 'text'"
                    [(ngModel)]="user.password"
                    formControlName="fcPassword"
                />
                <button mat-icon-button matSuffix (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="cadastroForm.value.fcPassword == '' || undefined">
                    Você deve digitar uma senha
                </mat-error>
                <mat-error *ngIf="cadastroForm.controls.fcPassword.hasError('minlength')">
                    A senha que você digitou tem menos que 6 caracteres
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Confirme a senha</mat-label>
                <input onpaste="return false"
                    matInput input.mat-input-element.my-custom-class
                    [type]="hideCheckedPwd ? 'password' : 'text'"
                    [(ngModel)]="user.rePassword"
                    formControlName="fcRePassword"
                />
                <button mat-icon-button matSuffix
                    (click)="hideCheckedPwd = !hideCheckedPwd"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideCheckedPwd">
                    <mat-icon>{{hideCheckedPwd ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="cadastroForm.value.rePassword == '' || undefined">
                    Digite a mesma senha acima
                </mat-error>
                <mat-error *ngIf="cadastroForm.get('fcRePassword').hasError('minlength')">
                A senha que você digitou tem menos que 6 caracteres
            </mat-error>
            <mat-error *ngIf="cadastroForm.get('fcRePassword').hasError('invalidPasswords') 
                && cadastroForm.value.fcCheckEmail !== ''">
                As senhas digitadas não conferem
            </mat-error>
            </mat-form-field>
        </form>
        </mat-card-content>
        <mat-card-footer>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd">
                <button mat-raised-button [routerLink]="['/login']">Voltar</button>
                <button  
                    mat-raised-button color="primary" 
                    [disabled]="this.cadastroForm.status == 'INVALID' "
                    (click)="userRegister()">
                        Criar Conta
                </button>
                <mat-progress-bar *ngIf="requestionServer" mode="indeterminate"></mat-progress-bar>
            </div>
        </mat-card-footer>
    </mat-card>
    <br>
    <br>
    <br>
</div>