import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CandidatePlataformComponent } from './plataform-candidate/home/candidate-plataform.component';
import { DocumentsComponent } from './Components/documents/documents.component';
import { FormComponent } from './Components/form/form.component';
import { GoodbyeComponent } from './Components/goodbye/goodbye.component';
import { LoginComponent } from './Components/login/login.component';
import { PasswordRecoveryComponent } from './Components/password-recovery/password-recovery.component';
import { RegisterConfirmationComponent } from './Components/register-confirmation/register-confirmation.component';
import { RegisterComponent } from './Components/register/register.component';
import { RegistrationCompletedComponent } from './Components/registration-completed/registration-completed.component';
import { StayWithUsComponent } from './Components/stay-with-us/stay-with-us.component';
import { TestComponent } from './Components/test/test.component';
import { RedactionComponent } from './plataform-tutor/redaction/redaction.component';
import { CreateUserComponent } from './plataform-tutor/create-user/create-user.component';
import { PlataformTutorComponent } from './plataform-tutor/home/plataform-tutor.component';
import { QuizComponent } from './plataform-tutor/quiz/quiz.component';
import { StudyCaseComponent } from './plataform-tutor/study-case/study-case.component';
import { DocumentUploadComponent } from './plataform-tutor/document-upload/document-upload.component';
import { InterviewComponent } from './plataform-tutor/interview/interview.component';
import { UserInfoComponent } from './plataform-tutor/user-info/user-info.component';
import { NewPasswordComponent } from './Components/new-password/new-password.component';


const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'inscricao', pathMatch: 'full', component: FormComponent },
  { path: 'login', pathMatch: 'full', component: LoginComponent },
  { path: 'nova-conta', pathMatch: 'full', component: RegisterComponent },
  /* { path: 'confirmacao', pathMatch: 'full', component: RegisterConfirmationComponent }, */
  { path: 'recuperacao-senha', pathMatch: 'full', component: PasswordRecoveryComponent },
  { path: 'nova-senha', pathMatch: 'full', component: NewPasswordComponent },
  { path: 'Test', pathMatch: 'full', component: TestComponent },
  { path: 'fique-conosco', pathMatch: 'full', component: StayWithUsComponent },
  { path: 'inscricao-completa', pathMatch: 'full', component: RegistrationCompletedComponent },
  { path: 'upload-documentos', pathMatch: 'full', component: DocumentsComponent },
  { path: 'ate-mais', pathMatch: 'full', component: GoodbyeComponent },
  { path: 'plataforma-tutor', pathMatch: 'full', component: PlataformTutorComponent },
  { path: 'plataforma-tutor/corrigir-redacao/:guid', pathMatch: 'full', component: RedactionComponent },
  { path: 'plataforma-tutor/entrevista/:guid', pathMatch: 'full', component: InterviewComponent },
  { path: 'plataforma-tutor/criar-usuario', pathMatch: 'full', component: CreateUserComponent },
  { path: 'plataforma-tutor/multipla-escolha/:guid', pathMatch: 'full', component: QuizComponent },
  { path: 'plataforma-tutor/estudo-de-caso/:guid', pathMatch: 'full', component: StudyCaseComponent },
  { path: 'plataforma-tutor/documentos/:guid', pathMatch: 'full', component: DocumentUploadComponent },
  { path: 'plataforma-tutor/info-usuario/:id_user/:guid', pathMatch: 'full', component: UserInfoComponent},
  { path: 'plataforma-candidato', pathMatch: 'full', component: CandidatePlataformComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes ,{ useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
