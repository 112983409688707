<div class="bg-darken" *ngIf="messageShow">
    <mat-card>
        <mat-card-title> {{ messageTitle }} </mat-card-title>
        <mat-card-content> {{ messageContent }} </mat-card-content>
        <br>
        <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
            <button mat-raised-button color="warn" [routerLink]="['/plataforma-tutor']">OK</button>
        </div>
    </mat-card>
</div>
<div class="form-content" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="redactionCard">
        <mat-card-title>Corrigir redação - {{ currentUser }}</mat-card-title>
        <mat-card-content>
            <div>
                <mat-spinner  diameter="60" *ngIf="loading"></mat-spinner>
                {{ redactionContent }}
            </div>
            <div fxLayout="row">
                <span><strong>Foco no trabalho: {{ workFocus }}</strong></span><br><br>
                <mat-slider color="primary" thumbLabel min="0" max="10" step="1" (blur)="saveCorrection(true)" [(ngModel)]="workFocus"></mat-slider>
            </div>
            <br>
            <span class="box-tip">
                <strong>DICA: </strong>
                O quanto o trabalho é importante para o candidato - quanto mais importante mais o perfil do NAU 
            </span>
            <br>
            <div fxLayout="row">
                <span><strong>Organização histórica: {{ organization }}</strong></span><br><br>
                <mat-slider color="primary" thumbLabel min="0" max="10" step="1" (blur)="saveCorrection(true)" [(ngModel)]="organization"></mat-slider>
            </div>
            <br>
            <span class="box-tip">
                <strong>DICA: </strong>
                Como ele consegue contar a história da sua vida até chegar ao momento atual
                - capacidade do avaliador entender o que está sendo relatado - quanto mais entender a história da vida mais o perfil do NAU
            </span>
            <br>
            <div fxLayout="row">
                <span><strong>Persistência: {{ persistence }}</strong></span><br><br>
                <mat-slider color="primary" thumbLabel min="0" max="10" step="1" (blur)="saveCorrection(true)" [(ngModel)]="persistence"></mat-slider>
            </div>
            <br>
            <span class="box-tip">
                <strong>DICA: </strong>
                Não desistir das coisas que começa - quanto mais persistência mais o perfil do NAU
            </span>
            <br>
            <div fxLayout="row">
                <span><strong>Resolução de problemas: {{ problemSolving }}</strong></span><br><br>
                <mat-slider color="primary" thumbLabel min="0" max="10" step="1" (blur)="saveCorrection(true)" [(ngModel)]="problemSolving"></mat-slider><br>
                <span *ngIf="saveMessage" class="success"><strong>Suas alterações foram salvas!</strong> </span>
            </div>
            <br>
            <span class="box-tip">
                <strong>DICA: </strong>
                Como ele lidou e resolveu os problemas que apareceram ao longo do caminho
                 - quanto mais capacidade de resolver os problemas mais o perfil do NAU
            </span>
            <br>
            <div fxLayout="row" fxLayoutAlign="end" class="textAlignEnd">
                <button mat-button color="secondary" [routerLink]="['/plataforma-tutor']">Cancelar</button>
                <button mat-raised-button color="primary" (click)="saveCorrection(true)">Salvar</button>
                <mat-progress-bar *ngIf="requestionServer" mode="indeterminate"></mat-progress-bar>
            </div>
        </mat-card-content>
    </mat-card>
</div>