<div class="register-content" fxLayout="row" fxLayoutAlign="center center">
    <div class="bg-darken" *ngIf="messageShow">
        <mat-card>
            <mat-card-title> {{ messageTitle }} </mat-card-title>
            <mat-card-content> {{ messageContent }} </mat-card-content>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-around center" class="textAlignEnd button-row">
                <button  
                    mat-raised-button color="warn"
                    (click)="toogleMessageShow(false);">
                        OK
                </button>
            </div>
        </mat-card>
    </div>
    <mat-card>
        <mat-card-title>
            Informações do usuário - {{ this.firstName + " " + this.lastName }}
        </mat-card-title>
        <mat-card-content>
            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Nome</mat-label>
                <input matInput type="text" readonly [value]="this.firstName" />
            </mat-form-field>
            <mat-form-field appearance="fill" layout-align="center center" style="margin-top:0 !important">
                <mat-label>Sobrenome</mat-label>
                <input matInput type="text" readonly [value]="this.lastName" />
            </mat-form-field>
            <mat-grid-list cols="2" rowHeight="4:1" [gutterSize]="'5px'">
                <mat-grid-tile>
                    <mat-form-field appearance="fill" layout-align="center center">
                        <mat-label>CPF</mat-label>
                        <input matInput type="text" readonly [value]="this.cpf" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill" layout-align="center center">
                        <mat-label>Escolha uma data</mat-label>
                        <input matInput [matDatepicker]="picker" readonly [value]="this.birthDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="4:1" [gutterSize]="'5px'">
                <mat-grid-tile>
                    <mat-form-field appearance="fill" layout-align="center center">
                        <mat-label>Telefone</mat-label>
                        <input matInput type="text" readonly [value]="this.phone" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-form-field appearance="fill" layout-align="center center">
                        <mat-label>E-mail</mat-label>
                        <input matInput type="text" readonly [value]="this.email" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
            <div>
              <app-give-up></app-give-up>
            </div>
            <div class="textAlignEnd">
                <button mat-button [routerLink]="['/plataforma-tutor/']">Voltar</button>
                <button mat-raised-button color="primary" [routerLink]="['/plataforma-tutor/entrevista/', this.guid]">Realizar Entrevista</button>
            </div>
        </mat-card-content>
        <mat-tab-group *ngIf="this.userProfile != 'PSICOLOGO'" mat-align-tabs="center">
            <mat-tab [disabled]="!quizActivity" label="Múltipla Escolha">
                <app-quiz *ngIf="quizActivity"></app-quiz>
            </mat-tab>
            <mat-tab  [disabled]="!redaction" label="Redação">
                <app-redaction *ngIf="redaction"></app-redaction>
            </mat-tab>
            <mat-tab [disabled]="!studyCase" label="Estudo de caso">
                <app-study-case *ngIf="studyCase"></app-study-case>
            </mat-tab>
            <mat-tab  [disabled]="!documents" label="Documentos">
                <app-document-upload *ngIf="documents"></app-document-upload>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
    <br>
    <br>
    <br>
</div>