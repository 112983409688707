import { ResidentClass } from "./resident.class";

export class InterviewClass {
    nome_candidato: string;
    nome_entrevistrador: string;
    perguntas: string[] = new Array();
    respostas: string[] = new Array();
    notas: string[] = new Array();
    residents : ResidentClass[];

    constructor() {
        this.nome_candidato = "";
        this.nome_entrevistrador = "";
        this.perguntas.push("01 - ME CONTE SOBRE VOCÊ:");
        this.perguntas.push("02 - QUAL O MOTIVO QUE VOCÊ GOSTARIA DE INGRESSAR NO NAU MERCADO DE TRABALHO?");
        this.perguntas.push("03 - QUAL O SEU OBJETIVO HOJE DE VIDA?");
        this.perguntas.push("04 - QUAIS CARACTERÍSTICAS VOCÊ POSSUI QUE IRÃO CONTRIBUIR COM O NAU?");
        this.perguntas.push("05 - VOCÊ POSSUI ALGUM HOBBY/PASSATEMPO? QUAL?");
        this.perguntas.push("06 - VOCÊ SE CONSIDERA UMA PESSOA ORGANIZADA? ME DÊ UM EXEMPLO?");
        this.perguntas.push("07 - QUAL FOI UM DESAFIO QUE VOCÊ JÁ VIVEU?");
        this.perguntas.push("08 - CONTE ALGUMA COISA QUE VOCÊ FEZ E QUE SE SENTIU ORGULHOSO DO RESULTADO.");
        //ASPECTOS PSICOLOGICOS
        this.perguntas.push("09 - DÊ UMA NOTA PARA O QUANTO VOCÊ SE SENTE SATISFEITO COM A SUA VIDA?");
        this.perguntas.push("10 - COM QUE FREQUÊNCIA VOCÊ SE SENTE ESTRESSADO?");
        this.perguntas.push("11 - COM QUE FREQUÊNCIA VOCÊ SE SENTE TRISTE?");
        this.perguntas.push("12 - COM QUE FREQUÊNCIA VOCÊ SE SENTE ANSIOSO?");
        this.perguntas.push("13 - VOCÊ  TEM HISTÓRICO DE DOENÇA OU TRANSTORNO PSIQUIÁTRICO/PSICOLÓGICO?");
        this.perguntas.push("14 - ALGUÉM DA SUA FAMÍLIA TEM HISTÓRICO DE DOENÇA OU TRANSTORNO PSIQUIÁTRICO/PSICOLÓGICO?");
        //QUESTIONÁRIO PSICOSSOCIAL - ASPECTOS SOCIAIS	
        this.perguntas.push("15 - VOCÊ POSSUI ALGUMA DEFICIÊNCIA?");
        this.perguntas.push("16 - VOCÊ TEM FILHOS?");
        this.perguntas.push("17 - VOCÊ PARTICIPA OU JÁ PARTICIPOU DE PROJETOS SOCIAIS?");
        this.perguntas.push("18 - VOCÊ PARTICIPA OU JÁ PARTICIPOU DE INSTITUIÇÕES RELIGIOSAS?");
        this.perguntas.push("19 - COM QUE FREQUÊNCIA VOCÊ PRATICA ESPORTES?");
        this.perguntas.push("20 - COM QUE FREQUÊNCIA VOCÊ TEM DIÁLOGO COM A SUA FAMÍLIA?");
        this.perguntas.push("21 - COM QUE FREQUÊNCIA SUA FAMÍLIA ESTÁ DISPOSTA A OUVI-LO(A) SOBRE OS SEUS PROBLEMAS?"); 
        this.perguntas.push("22 - COM QUE FREQUÊNCIA VOCÊ TEM AJUDA E APOIO DE AMIGOS/COLEGAS?"); 
        this.perguntas.push("23 - COM QUE FREQUÊNCIA VOCÊ É RESPONSÁVEL PELAS TAREFAS DOMÉSTICAS DA SUA CASA, COMO LAVAR A LOUÇA, FAXINAR, COZINHAR, ETC?");
        this.perguntas.push("24 - COM QUE FREQUÊNCIA CARNES, LEGUMES E FRUTAS FAZEM PARTE DA SUA ROTINA ALIMENTAR?"); 
        this.perguntas.push("25 - COM QUE FREQUÊNCIA ACONTECEM SITUAÇÕES COMO: CRIMES, ROUBOS, ASSALTOS, VENDAS DE DROGAS NO BAIRRO ONDE VOCÊ MORA?"); 
        this.perguntas.push("26 - SUA CASA TEM QUAIS CÔMODOS? [SALA]");
        this.perguntas.push("27 - SUA CASA TEM QUAIS CÔMODOS? [SALA DE JANTAR]");
        this.perguntas.push("28 - SUA CASA TEM QUAIS CÔMODOS? [COZINHA]");
        this.perguntas.push("29 - SUA CASA TEM QUAIS CÔMODOS? [QUARTO]");
        this.perguntas.push("30 - SUA CASA TEM QUAIS CÔMODOS? [BANHEIRO]");
        this.perguntas.push("31 - NA SUA CASA TEM QUAIS ITENS? [GELADEIRA]");
        this.perguntas.push("32 - NA SUA CASA TEM QUAIS ITENS? [FOGÃO]");
        this.perguntas.push("33 - NA SUA CASA TEM QUAIS ITENS? [MICROONDAS]");
        this.perguntas.push("34 - NA SUA CASA TEM QUAIS ITENS? [MÁQUINA DE LAVAR]");
        this.perguntas.push("35 - NA SUA CASA TEM QUAIS ITENS? [TANQUINHO]");
        this.perguntas.push("36 - NA SUA CASA TEM QUAIS ITENS? [TV]");
        this.perguntas.push("37 - NA SUA CASA TEM QUAIS ITENS? [TV A CABO (TIPO SKY)]");
        this.perguntas.push("38 - NA SUA CASA TEM QUAIS ITENS? [COMPUTADOR PC]");
        this.perguntas.push("39 - NA SUA CASA TEM QUAIS ITENS? [NOTEBOOK]");
        this.perguntas.push("40 - NA SUA CASA TEM QUAIS ITENS? [ACESSO À INTERNET]");
        this.perguntas.push("41 - NA SUA CASA TEM QUAIS ITENS? [CELULAR]");
        this.perguntas.push("42 - NA SUA CASA TEM QUAIS ITENS? [CARRO]");
        this.perguntas.push("43 - NA SUA CASA TEM QUAIS ITENS? [MOTO]");
        this.perguntas.push("44 - Número de pessoas por cômodo ( informação derivada )");
        //AVALIAÇÃO FINAL
        this.perguntas.push("45 - RELATÓRIO SOCIAL (Pontuação de relevância do Jovem no Projeto)");
        this.perguntas.push("46 - RELATÓRIO PSICOLÓGICO (Pontuação de relevância do Jovem no Projeto)");
        this.perguntas.push("47 - NOTA DA REDAÇÃO (Pontuação de relevância do Jovem no Projeto)");
        
        //MELHORIAS PEDIDAS DEPOIS DA APROVAÇÃO DO QUESTIONARIO
        this.perguntas.push("48 - ANOTAÇÕES");
        this.perguntas.push("15.a - OBSERVAÇÕES"); // 49 INDICE [48]

        this.perguntas.push("49 - TEM PREFERÊNCIA PELO HORÁRIO:");
     }
}