import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent implements OnInit {

  public currentUser = sessionStorage.getItem('currentUser');
  public saveMessage: boolean = false;
  /* displayDoc: boolean = false; */

  public rg = {
    url: '',
    situation: 'Aguardando aprovação',
    class: ''
  }

  public cpf = {
    url: '',
    situation: 'Aguardando aprovação',
    class: ''
  }

  public certNasc = {
    url: '',
    situation: 'Aguardando aprovação',
    class: ''
  }

  public cmpRenda = {
    url: '',
    situation: 'Aguardando aprovação',
    class: ''
  }

  public cmpResid = {
    url: '',
    situation: 'Aguardando aprovação',
    class: ''
  }


  constructor(private userService: UserService, private route: ActivatedRoute) { }

  private token: string = sessionStorage.getItem('authToken');

  ngOnInit(): void {

    this.userService.getUploadedFiles(this.token, this.route.snapshot.paramMap.get('guid'))
      .subscribe(
        result => {
          this.rg.url = result.rg;
          this.cpf.url = result.cpf;
          this.cmpRenda.url = result.comp_renda;
          this.cmpResid.url = result.comp_residencia;
          this.certNasc.url = result.cert_nascimento;
        }
      )

    this.getApproval();
  }

  messageTitle: string;
  messageContent: string;
  messageShow: boolean = false;

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

  save(autoSave: boolean) {

    const reqBody = {
      rg_approval: (this.rg.situation == 'Aprovado'),
      cpf_approval: (this.cpf.situation == 'Aprovado'),
      residencia_approval: (this.cmpResid.situation == 'Aprovado'),
      renda_approval: (this.cmpRenda.situation == 'Aprovado'),
      nasc_approval: (this.certNasc.situation == 'Aprovado')
    };

    this.userService.saveDocApproval(this.token, Number(sessionStorage.getItem('idUser')), reqBody)
      .subscribe(
        result => {
          if(autoSave) {
            this.saveMessage = true;
            setTimeout(() => {
              this.saveMessage = false;
            }, 2000);
            return;
          }
          this.messageBox('Sucesso', 'Suas alterações foram salvas!');
        }
      )
  }

  getApproval() {
    const idUser = Number(this.route.snapshot.paramMap.get("id_user"));
    this.userService.getApprovedDocs(this.token,  idUser)
      .subscribe(
        result => {
          this.cpf.situation = result.cpf_approval ? 'Aprovado' : 'Aguardando aprovação';
          this.cpf.class = result.cpf_approval ? 'aproved' : '';
          this.rg.situation = result.rg_approval ? 'Aprovado' : 'Aguardando aprovação';
          this.rg.class = result.rg_approval ? 'aproved' : '';
          this.cmpRenda.situation = result.renda_approval ? 'Aprovado' : 'Aguardando aprovação';
          this.cmpRenda.class = result.renda_approval ? 'aproved' : '';
          this.cmpResid.situation = result.residencia_approval ? 'Aprovado' : 'Aguardando aprovação';
          this.cmpResid.class = result.residencia_approval ? 'aproved' : '';
          this.certNasc.situation = result.nasc_approval ? 'Aprovado' : 'Aguardando aprovação';
          this.certNasc.class = result.nasc_approval ? 'aproved' : '';
        }
      )
  }

  /* closeDocument() {
    this.displayDoc = false;
  } */

  toggleDocSituation(doc: string) {
    switch(doc) {
      case 'cpf':
        this.cpf.situation = this.cpf.situation == 'Aprovado'? 'Aguardando aprovação': 'Aprovado';
        this.cpf.class = this.cpf.situation == 'Aprovado'? 'aproved': '';
      break;
      case 'rg':
        this.rg.situation = this.rg.situation == 'Aprovado'? 'Aguardando aprovação': 'Aprovado';
        this.rg.class = this.rg.situation == 'Aprovado'? 'aproved': '';
      break;
      case 'cert_nasc':
        this.certNasc.situation = this.certNasc.situation == 'Aprovado'? 'Aguardando aprovação': 'Aprovado';
        this.certNasc.class = this.certNasc.situation == 'Aprovado'? 'aproved': '';
      break;
      case 'cmp_renda':
        this.cmpRenda.situation = this.cmpRenda.situation == 'Aprovado'? 'Aguardando aprovação': 'Aprovado';
        this.cmpRenda.class = this.cmpRenda.situation == 'Aprovado'? 'aproved': '';
      break;
      case 'cmp_resid':
        this.cmpResid.situation = this.cmpResid.situation == 'Aprovado'? 'Aguardando aprovação': 'Aprovado';
        this.cmpResid.class = this.cmpResid.situation == 'Aprovado'? 'aproved': '';
      break;
    }
    this.save(true);
  }
}


