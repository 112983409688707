import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserActivityService } from 'src/app/Services/user-activity.service';

@Component({
  selector: 'app-study-case',
  templateUrl: './study-case.component.html',
  styleUrls: ['./study-case.component.css']
})
export class StudyCaseComponent implements OnInit {

  public grade: number = 0;
  public studyCaseContent: string;
  messageTitle: string;
  messageContent: string;
  messageShow: boolean = false;

  public loading: boolean = false;
  public saveMessage: boolean = false;
  requestionServer: boolean = false;

  public currentUser = sessionStorage.getItem('currentUser');
  public idUser = Number(sessionStorage.getItem('idUser'));
  private jwtToken = sessionStorage.getItem('authToken');

  toogleMessageShow(show: boolean): void {
    this.messageShow = show;
  }

  constructor(
    private activityService: UserActivityService,
    private route: ActivatedRoute,
    private router: Router
  ) { }


  ngOnInit(): void {

    const candidateGuid = this.route.snapshot.paramMap.get("guid");

    this.loading = true;
    this.requestionServer = true;
    this.activityService.getActivity(this.jwtToken, candidateGuid, 'estudo_caso')
    .subscribe(
      data => {
          this.studyCaseContent = data.result[0].stages[0].questions[0].answer;
          const idUser = Number(this.route.snapshot.paramMap.get("id_user"));
          this.activityService.getCorrection(this.jwtToken, idUser)
          .subscribe(
            data => {
              this.grade = data.case_study_score;
              this.requestionServer = false;
            },
            error => {
              this.requestionServer = false;
              if(error.status == 404) {
                return this.grade = 0;
              }
              console.log(error);
              this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
            }
          )
          this.loading = false;
        },
        error => {
          if(error.status == 401) {
            this.requestionServer = false;
            alert('Sua sessão expirou, efetue login novamente!');
            return this.router.navigate(['login']);
          }
          // TODO - substituir por dialog
          alert('Este usuário ainda não realizou a etapa de caso de estudo');
          console.log(error);
        }
      )
  }

  saveCorrection(autoSave: boolean ) {
    try {
      this.requestionServer = true;
      this.activityService.saveCorrection(this.jwtToken, this.idUser, {
        case_study_score: this.grade
      })
        .subscribe(
          result => {
            if(autoSave) {
              this.saveMessage = true;
              setInterval(() => {
                this.saveMessage = false;
              }, 2000);
              return;
            }
            alert('Suas alterações foram salvas!');
          }
        )
    } catch(error) {
      this.requestionServer = false;
      console.log(error);
      this.messageBox('Erro', 'Houve um erro ao processar sua requisição!');
    }

  }

  messageBox(title: string, content: string): void {
    this.messageTitle = title;
    this.messageContent = content;
    this.toogleMessageShow(true);
    window.scrollTo(0, 0);
  }

}
