import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-plataform-tutor',
  templateUrl: './plataform-tutor.component.html',
  styleUrls: ['./plataform-tutor.component.css']
})
export class PlataformTutorComponent implements OnInit {

  public userName: string;
  public search: string;
  public order: string;
  public filter: string;
  public userProfile: string;
  public userList = [];
  public orderOptions: object[] = [
    {
      value: 'asc',
      label: 'A > Z'
    },
    {
      value: 'desc',
      label: 'Z > A'
    }
  ]
  public filterOptions: object[] = [
    {
      value: 'multiple_choice',
      label: 'Prova múltipla escolha'
    },
    {
      value: 'family_income_score',
      label: 'Renda familiar'
    },
    {
      value: 'redaction',
      label: 'Redação'
    },
    {
      value: 'interview_status',
      label:  'Entrevista'
    },
    {
      value: 'rg',
      label: 'Documentos'
    },
    {
      value: 'final_score',
      label: 'Pontuação'
    },
    {
      value: 'first_name',
      label: 'Ordem Alfabética - Nomes de usuário'
    }
  ];

  public itemSort: string = 'id_user';
  public loading: boolean;
  public jwtToken = sessionStorage.getItem('authToken');
  public itemLength: number;
  public pageIndex: number = 0;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {

    if(!sessionStorage.getItem('authToken')) {
      alert('Você não tem autorização para acessar essa página!');
      this.router.navigate(['/login']);
    }

    const jwtHelper = new JwtHelperService();
    const userData = jwtHelper.decodeToken(this.jwtToken);

    this.userProfile = userData.profile;

    if (userData.profile == 'CANDIDATO' || userData.profile == null) {
      alert('Você não tem autorização para acessar essa página!');
      this.router.navigate(['/login']);
    }

    this.userService.calculateUsersScores(this.jwtToken)
      .subscribe(
        result => {
          console.log(result);
        },
        error => {
          console.error(error);
        }
      )

    this.userName = userData.first_name;
    this.loadUserData(this.pageIndex, this.itemSort);

  }

  accessUserPage(page: string, user: any) {
    if(user.interview_status == "FINISHED") {
      this.router.navigate(['/plataforma-tutor/interview-finished']);
    }
    else {
      const userName = user.first_name + " " + user.last_name;
      const userGuid = user.guid;
      sessionStorage.setItem('currentUser', userName);
      sessionStorage.setItem('idUser', user.id_user);
      this.router.navigate([`/plataforma-tutor/${page}/`, userGuid]);
    }
  }

  nextPage(event: PageEvent) {
    const pageIndex = event.pageIndex;
    this.loadUserData(pageIndex, this.itemSort, this.search, this.order);
  }

  sortTable(option: string) {
    this.itemSort = option;
    this.pageIndex = 0;
    this.loadUserData(this.pageIndex, this.itemSort);
  }

  alphaBethicSort(alphaBechitOrder: string) {
    this.order = alphaBechitOrder;
    this.pageIndex = 0;
    this.loadUserData(this.pageIndex, this.itemSort, '', alphaBechitOrder);
  }

  logOut() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  searchCandidate(search: string) {

    if(search == '' || search == undefined) {
      return this.loadUserData(0, 'id_user');
    }

    const searchString = search[0].toUpperCase() + search.substr(1);
    this.search = searchString;

    this.pageIndex = 0;
    this.itemSort = 'id_user';

    this.loadUserData(this.pageIndex, this.itemSort, searchString);
  }

  refresh() {
    this.pageIndex = 0
    this.loadUserData(this.pageIndex, 'id_user');
    this.itemSort = 'id_user';
    this.search = "";
    this.order = "";
    this.filter = "";
  }

  loadUserData(page: number, sort: string, searchString: string = '', alphaBethicOrder: string = '') {

    let userList: Observable<any>;

    this.pageIndex = page;
    this.loading = true;

    if (searchString != '') {
      userList = this.userService.getAllUsers(this.jwtToken, (page + 1), sort, searchString, alphaBethicOrder)
    } else {
      userList = this.userService.getAllUsers(this.jwtToken, (page + 1), sort, '', alphaBethicOrder)
    }

    userList
      .subscribe(
        result => {
          this.itemLength = result.pagination.total;
          function verifyCorrection(user: any) {

            const newUser = {
              guid: user.guid,
              id_user: user.id_user,
              first_name: user.first_name,
              last_name: user.last_name,
              multiple_choice: {},
              family_income: {},
              redaction: {},
              documents: {},
              interview_status: {},
              final_score: '-',
              classification: '-',
              status: {
                label: 'Em andamento',
                class: ''
              }
            };

            if(user.interview_status == "FINISHED") {

              newUser.interview_status = {
                label: 'Realizada',
                class: 'success'
              }

            } else if ( user.interview_status == 'STARTED' ) {

              //TODO QUANDO UMA ENTREVISTA ESTIVER SERNDO REALIZADA ELA NÃO PODE SER ABERTA POR OUTRO PSICOLOGO
              newUser.interview_status = {
                label: 'Em andamento',
                class: ''
              }

            } else {

              newUser.interview_status = {
                label: 'Não realizada',
                class: 'primary'
              }

            }

            if(
              user.rg != null               &&
              user.cpf != null              &&
              user.comp_residencia != null  &&
              user.comp_renda != null       &&
              user.cert_nascimento != null
            ) {

              newUser.documents = {
                label: 'Enviados',
                class: 'primary',
                disabled: false
              }

              if(
                user.rg_approval === false ||
                user.cpf_approval === false ||
                user.renda_approval === false ||
                user.nasc_approval === false ||
                user.residencia_approval === false
              ) {
                newUser.documents = {
                  label: 'Exigência',
                  class: 'danger',
                  disabled: false
                }
              } else {
                newUser.documents = {
                  label: 'Aprovados',
                  class: 'success',
                  disabled: false
                }
              }

            } else {
                newUser.documents = {
                  label: 'Pendente',
                  class: '',
                  disabled: true
              }
            }

            if (user.multiple_choice == "FINISHED") {

              newUser.multiple_choice = {
                label: 'Realizado',
                class: 'success',
                disabled: false
              }

            } else {

              newUser.multiple_choice = {
                label: 'Não Realizado',
                class: '',
                disabled: true
              }

            }

            if (user.family_income == "FINISHED") {
              newUser.family_income = {
                label: user.family_income_answer ? user.family_income_answer : 'Realizado',
                class: 'success',
                disabled: false
              }
            } else {
              newUser.family_income = {
                label: 'Não Realizado',
                class: '',
                disabled: true
              }
            }

            if (user.redaction == "FINISHED") {

              if (user.problem_resolution_score != null) {
                newUser.redaction = {
                  label: 'Avaliado ',
                  class: 'success',
                  disabled: false
                }
              } else {
                newUser.redaction = {
                  label: 'Não avaliado ',
                  class: 'primary',
                  disabled: false
                }
              }
            } else {
              newUser.redaction = {
                label: 'Não Realizado',
                class: '',
                disabled: true
              }
            }

            if(user.final_score !== null) {

              newUser.final_score = user.final_score;
              newUser.classification = user.position + 'º Colocado';
            }

            if(user.interview_content !== null && user.interview_content.desistencia !== undefined) {
              newUser.status.label = 'Desistente';
              newUser.status.class = 'danger';
            }

            return newUser;
          }

          this.userList = result.data.map(verifyCorrection);
          this.loading = false;
        },
        error => {
          if (error.status == 401) {
            alert('Sua sessão expirou, efetue login novamente!');
            this.router.navigate(['login']);
          }
        }
      )
  }

}
